import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const VariantErrorRibbon: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 190 50" fill="currentColor" className={twMerge('h-12', className)} {...props}>
      <path
        d="M105.83 21.6561L97.75 13.0068L179.986 15.8049C184.021 15.9421 186.039 16.0108 187.371 17.1546C187.649 17.3936 187.901 17.6625 188.12 17.9565C189.171 19.3633 189.102 21.3808 188.965 25.4157L188.459 40.2853C188.322 44.3202 188.253 46.3376 187.109 47.6699C186.87 47.9482 186.601 48.1994 186.307 48.419C184.9 49.4696 182.883 49.4009 178.848 49.2636L96.6116 46.4656L105.261 38.3855L97.1808 29.7362L105.83 21.6561Z"
        fill="#E5E7EB"
      />
      <mask id="mask0_4687_197207" maskUnits="userSpaceOnUse" x="96" y="13" width="94" height="37">
        <path
          d="M105.83 21.6561L97.75 13.0068L179.986 15.8049C184.021 15.9421 186.039 16.0108 187.371 17.1546C187.649 17.3936 187.901 17.6625 188.12 17.9565C189.171 19.3633 189.102 21.3808 188.965 25.4157L188.459 40.2853C188.322 44.3202 188.253 46.3376 187.109 47.6699C186.87 47.9482 186.601 48.1994 186.307 48.419C184.9 49.4696 182.883 49.4009 178.848 49.2636L96.6116 46.4656L105.261 38.3855L97.1808 29.7362L105.83 21.6561Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4687_197207)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1825 18.0902C13.9081 18.0469 12.8398 19.0448 12.7965 20.3192C12.7531 21.5936 13.7511 22.6619 15.0255 22.7052L167.321 27.8869C168.595 27.9303 169.663 26.9323 169.707 25.6579C169.75 24.3835 168.752 23.3153 167.478 23.2719L15.1825 18.0902ZM14.8688 27.3202C13.5944 27.2768 12.5261 28.2748 12.4828 29.5492C12.4394 30.8236 13.4374 31.8918 14.7118 31.9352L128.549 35.8084C129.823 35.8518 130.891 34.8538 130.935 33.5794C130.978 32.305 129.98 31.2368 128.706 31.1934L14.8688 27.3202Z"
          fill="#D1D5DB"
        />
      </g>
      <path
        d="M174.842 22.6859L181.935 30.2781C182.237 30.6024 182.752 30.6199 183.076 30.317C183.401 30.014 183.418 29.4993 183.115 29.1751L176.023 21.5828C175.72 21.2585 175.205 21.241 174.881 21.544C174.557 21.8469 174.539 22.3616 174.842 22.6859Z"
        fill="#D1D5DB"
        stroke="#D1D5DB"
        strokeWidth="0.75"
      />
      <path
        d="M175.765 30.1903L183.357 23.0977C183.681 22.7947 183.699 22.2801 183.396 21.9558C183.093 21.6315 182.578 21.614 182.254 21.9169L174.662 29.0095C174.338 29.3125 174.32 29.8272 174.623 30.1514C174.926 30.4757 175.441 30.4932 175.765 30.1903Z"
        fill="#D1D5DB"
        stroke="#D1D5DB"
        strokeWidth="0.75"
      />
      <path
        d="M94.555 25.4665L86.645 34.7301L8.58745 43.0812C5.8417 43.375 3.37768 41.3872 3.08392 38.6415L0.586322 15.2965C0.292563 12.5508 2.28029 10.0868 5.02605 9.79302L83.0836 1.4419L92.7743 8.82243L84.8643 18.086L94.555 25.4665Z"
        fill="#E5E7EB"
      />
      <mask id="mask1_4687_197207" maskUnits="userSpaceOnUse" x="0" y="1" width="95" height="43">
        <path
          d="M94.555 25.4665L86.645 34.7301L8.58745 43.0812C5.8417 43.375 3.37768 41.3872 3.08392 38.6415L0.586322 15.2965C0.292563 12.5508 2.28029 10.0868 5.02605 9.79302L83.0836 1.4419L92.7743 8.82243L84.8643 18.086L94.555 25.4665Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_4687_197207)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4442 17.1445C9.17627 17.2802 8.2584 18.418 8.39405 19.6859C8.5297 20.9538 9.6675 21.8716 10.9354 21.736L162.454 5.52552C163.722 5.38987 164.64 4.25207 164.504 2.98417C164.369 1.71627 163.231 0.7984 161.963 0.934048L10.4442 17.1445ZM11.4318 26.3278C10.1639 26.4635 9.24605 27.6013 9.3817 28.8692C9.51735 30.1371 10.6551 31.055 11.923 30.9193L125.179 18.8024C126.447 18.6667 127.365 17.5289 127.23 16.261C127.094 14.9931 125.956 14.0753 124.688 14.2109L11.4318 26.3278Z"
          fill="#D1D5DB"
        />
      </g>
    </Svg>
  )
}
