import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const InformationCircle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 17 18"
      fill="currentColor"
      className={twMerge('h-[17px] w-[18px]', className)}
      {...props}
    >
      <path d="M8.17188 17.0625C3.72438 17.0625 0.109375 13.4475 0.109375 9C0.109375 4.5525 3.72438 0.9375 8.17188 0.9375C12.6194 0.9375 16.2344 4.5525 16.2344 9C16.2344 13.4475 12.6194 17.0625 8.17188 17.0625ZM8.17188 2.0625C4.34688 2.0625 1.23438 5.175 1.23438 9C1.23438 12.825 4.34688 15.9375 8.17188 15.9375C11.9969 15.9375 15.1094 12.825 15.1094 9C15.1094 5.175 11.9969 2.0625 8.17188 2.0625Z" />
      <path d="M8.17188 10.3125C7.86437 10.3125 7.60938 10.0575 7.60938 9.75V6C7.60938 5.6925 7.86437 5.4375 8.17188 5.4375C8.47938 5.4375 8.73438 5.6925 8.73438 6V9.75C8.73438 10.0575 8.47938 10.3125 8.17188 10.3125Z" />
      <path d="M8.17188 12.75C8.07438 12.75 7.97687 12.7275 7.88687 12.69C7.79687 12.6525 7.71437 12.6 7.63937 12.5325C7.57187 12.4575 7.51937 12.3825 7.48187 12.285C7.44437 12.195 7.42188 12.0975 7.42188 12C7.42188 11.9025 7.44437 11.805 7.48187 11.715C7.51937 11.625 7.57187 11.5425 7.63937 11.4675C7.71437 11.4 7.79687 11.3475 7.88687 11.31C8.06687 11.235 8.27688 11.235 8.45688 11.31C8.54688 11.3475 8.62937 11.4 8.70438 11.4675C8.77188 11.5425 8.82438 11.625 8.86188 11.715C8.89938 11.805 8.92188 11.9025 8.92188 12C8.92188 12.0975 8.89938 12.195 8.86188 12.285C8.82438 12.3825 8.77188 12.4575 8.70438 12.5325C8.62937 12.6 8.54688 12.6525 8.45688 12.69C8.36688 12.7275 8.26937 12.75 8.17188 12.75Z" />
    </Svg>
  )
}
