import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const WordPressLogo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 21" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M1.92188 10.7501C1.92188 14.1457 3.89522 17.0801 6.75672 18.4707L2.66459 7.25879C2.1886 8.32569 1.92188 9.50637 1.92188 10.7501Z"
        fill="currentColor"
      />
      <path
        d="M16.2909 10.3173C16.2909 9.25708 15.9101 8.52285 15.5834 7.95136C15.1486 7.24472 14.741 6.6463 14.741 5.93966C14.741 5.15107 15.3391 4.417 16.1815 4.417C16.2196 4.417 16.2556 4.42174 16.2927 4.42386C14.7665 3.02559 12.733 2.17188 10.4997 2.17188C7.50268 2.17188 4.86595 3.70955 3.33203 6.03858C3.5333 6.04462 3.72298 6.04886 3.88409 6.04886C4.78139 6.04886 6.17036 5.93998 6.17036 5.93998C6.6328 5.91272 6.68732 6.59194 6.22537 6.64663C6.22537 6.64663 5.76064 6.70131 5.24351 6.72841L8.36749 16.0205L10.2449 10.3901L8.90829 6.72808C8.44634 6.70098 8.0087 6.6463 8.0087 6.6463C7.54642 6.6192 7.60062 5.9124 8.0629 5.93966C8.0629 5.93966 9.47961 6.04853 10.3226 6.04853C11.2197 6.04853 12.6088 5.93966 12.6088 5.93966C13.0716 5.9124 13.1259 6.59162 12.6638 6.6463C12.6638 6.6463 12.1981 6.70098 11.682 6.72808L14.7821 15.9499L15.6378 13.0905C16.0087 11.9039 16.2909 11.0517 16.2909 10.3173Z"
        fill="currentColor"
      />
      <path
        d="M10.652 11.5L8.07812 18.9792C8.84663 19.2052 9.65938 19.3287 10.5015 19.3287C11.5005 19.3287 12.4585 19.156 13.3503 18.8425C13.3273 18.8057 13.3064 18.7667 13.2892 18.7243L10.652 11.5Z"
        fill="currentColor"
      />
      <path
        d="M18.0274 6.63379C18.0643 6.90704 18.0852 7.20038 18.0852 7.51591C18.0852 8.38644 17.9226 9.36503 17.4329 10.5886L14.8125 18.1648C17.3629 16.6776 19.0783 13.9145 19.0783 10.7498C19.0785 9.25828 18.6975 7.85576 18.0274 6.63379Z"
        fill="currentColor"
      />
      <path
        d="M10.5001 0.75C4.98618 0.75 0.5 5.23585 0.5 10.7498C0.5 16.2643 4.98618 20.75 10.5001 20.75C16.0138 20.75 20.5007 16.2643 20.5007 10.7498C20.5005 5.23585 16.0138 0.75 10.5001 0.75ZM10.5001 20.2916C5.23903 20.2916 0.958526 16.0111 0.958526 10.7498C0.958526 5.4887 5.23887 1.20853 10.5001 1.20853C15.761 1.20853 20.0411 5.4887 20.0411 10.7498C20.0411 16.0111 15.761 20.2916 10.5001 20.2916Z"
        fill="currentColor"
      />
    </Svg>
  )
}
