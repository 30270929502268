import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const SmsNotification: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 87 86" className={twMerge('h-auto w-24', className)} {...props}>
      <path
        d="M70.3751 28.6667C75.3226 28.6667 79.3334 24.6559 79.3334 19.7083C79.3334 14.7608 75.3226 10.75 70.3751 10.75C65.4275 10.75 61.4167 14.7608 61.4167 19.7083C61.4167 24.6559 65.4275 28.6667 70.3751 28.6667Z"
        fill="#5A36FF"
      />
      <path
        d="M70.3751 28.6667C75.3226 28.6667 79.3334 24.6559 79.3334 19.7083C79.3334 14.7608 75.3226 10.75 70.3751 10.75C65.4275 10.75 61.4167 14.7608 61.4167 19.7083C61.4167 24.6559 65.4275 28.6667 70.3751 28.6667Z"
        fill="#FF0000"
      />
      <path
        d="M74.7467 33.3602C72.2384 34.1485 69.4793 34.2918 66.6126 33.5752C61.8109 32.321 57.9051 28.4869 56.5793 23.6852C55.9342 21.356 55.8984 19.0627 56.2926 16.9843C56.7584 14.691 55.1459 12.541 52.8526 12.541H25.5834C14.8334 12.541 7.66675 17.916 7.66675 30.4577V55.541C7.66675 68.0827 14.8334 73.4577 25.5834 73.4577H61.4168C72.1667 73.4577 79.3334 68.0827 79.3334 55.541V36.7644C79.3334 34.3994 77.0401 32.6077 74.7467 33.3602ZM56.1134 39.9535L51.8851 43.3219C49.5201 45.221 46.5101 46.1527 43.5001 46.1527C40.4901 46.1527 37.4442 45.221 35.1151 43.3219L23.8993 34.3635C22.7526 33.4319 22.5734 31.7118 23.4692 30.5652C24.4009 29.4185 26.0851 29.2035 27.2317 30.1352L38.4476 39.0935C41.1709 41.2794 45.7934 41.2794 48.5168 39.0935L52.7451 35.7252C53.8918 34.7935 55.6118 34.9727 56.5076 36.1552C57.4393 37.3019 57.2601 39.0218 56.1134 39.9535Z"
        fill="#5A36FF"
      />
    </Svg>
  )
}
