import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const BoxDottedBlocked: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 22 22" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M1.17188 21L21.1719 1"
        stroke="#9CA3AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5.17188"
        y="5"
        width="12"
        height="12"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2.5"
      />
    </Svg>
  )
}
