import * as React from 'react'
import { MouseEventHandler, SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type Props = SVGProps<SVGSVGElement> & {
  title?: string
}

export const Svg: React.FC<Props> = ({ title, children, onClick, className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.svg(className, onClick)}
      viewBox="0 0 24 24"
      fill="none"
      stroke="none"
      color="none"
      {...props}
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  )
}

const classes = {
  svg: (className?: string, onClick?: MouseEventHandler<SVGSVGElement>) =>
    twMerge(className, onClick && 'cursor-pointer')
}
