import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Funnel: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[20px] w-[21px]', className)}
      viewBox="0 0 21 20"
      {...props}
    >
      <path
        d="M14.8167 16.875C13.9334 16.875 13.075 16.6167 12.3417 16.125C11.7667 15.775 11.2667 15.2584 10.9001 14.65C10.4667 13.9417 10.2334 13.125 10.2334 12.2917C10.2334 9.76671 12.2917 7.70837 14.8167 7.70837C15.1667 7.70837 15.5084 7.75005 15.8334 7.82505C17.9 8.28339 19.4001 10.1584 19.4001 12.2917C19.4001 13.1334 19.1751 13.95 18.7418 14.6584C17.9251 16.025 16.4167 16.875 14.8167 16.875ZM14.8167 8.95837C12.9751 8.95837 11.4834 10.45 11.4834 12.2917C11.4834 12.9 11.6501 13.4917 11.9667 14.0084C12.2334 14.45 12.5917 14.8167 13.0084 15.075C13.5584 15.4417 14.1751 15.625 14.8167 15.625C15.9834 15.625 17.0751 15.0084 17.6751 14.0084C17.9917 13.4917 18.1501 12.9 18.1501 12.2917C18.1501 10.7417 17.0584 9.37505 15.5501 9.04171C15.3084 8.98338 15.0667 8.95837 14.8167 8.95837Z"
        fill="#515CC6"
      />
      <path
        d="M14.2498 13.8916C14.0914 13.8916 13.9331 13.8333 13.8081 13.7083L12.8331 12.7333C12.5914 12.4916 12.5914 12.0916 12.8331 11.85C13.0748 11.6083 13.4748 11.6083 13.7164 11.85L14.2664 12.4L15.9414 10.8583C16.1998 10.625 16.5914 10.6416 16.8248 10.8916C17.0581 11.1416 17.0414 11.5416 16.7914 11.775L14.6748 13.725C14.5581 13.8333 14.3998 13.8916 14.2498 13.8916Z"
        fill="#515CC6"
      />
      <path
        d="M9.85834 18.9583C9.45834 18.9583 9.05833 18.8583 8.69999 18.6583C7.95832 18.2416 7.51667 17.4916 7.51667 16.65V12.1916C7.51667 11.7666 7.24165 11.1416 6.97499 10.8166L3.80833 7.49162C3.28333 6.94995 2.875 6.04164 2.875 5.37497V3.43329C2.875 2.09162 3.89168 1.04163 5.18335 1.04163H16.3C17.575 1.04163 18.6083 2.07495 18.6083 3.34995V5.19995C18.6083 6.07495 18.0917 7.05829 17.5834 7.57496L16.0834 8.89995C15.9334 9.03329 15.725 9.0833 15.525 9.04163C15.3 8.9833 15.0583 8.95829 14.8083 8.95829C12.9667 8.95829 11.475 10.45 11.475 12.2916C11.475 12.9 11.6417 13.4916 11.9584 14.0083C12.225 14.45 12.5834 14.8166 13 15.075C13.1834 15.1916 13.3 15.3916 13.3 15.6083V15.8916C13.3 16.55 12.9 17.475 12.2334 17.8666L11.0834 18.6083C10.7167 18.8416 10.2833 18.9583 9.85834 18.9583ZM5.19169 2.29163C4.60002 2.29163 4.13334 2.79162 4.13334 3.43329V5.37497C4.13334 5.67497 4.38335 6.28329 4.71668 6.61663L7.925 9.99162C8.35 10.5166 8.77501 11.4 8.77501 12.1916V16.65C8.77501 17.2 9.15837 17.4749 9.3167 17.5666C9.67503 17.7666 10.1 17.7583 10.425 17.5583L11.5917 16.8083C11.8167 16.675 12.0417 16.25 12.0583 15.95C11.6 15.6083 11.2 15.1666 10.9 14.6666C10.4667 13.9583 10.2333 13.1416 10.2333 12.3083C10.2333 9.78328 12.2917 7.72495 14.8167 7.72495C15.05 7.72495 15.2834 7.74161 15.5 7.77495L16.7333 6.68328C17.0167 6.39162 17.3667 5.69996 17.3667 5.20829V3.35829C17.3667 2.77496 16.8917 2.29997 16.3083 2.29997H5.19169V2.29163Z"
        fill="#515CC6"
      />
    </Svg>
  )
}
