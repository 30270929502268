import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ChevronSmallDown: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 18 9"
      className={twMerge('h-[9px] w-[18px]', className)}
      fill="none"
      {...props}
    >
      <path d="M8.99999 8.80001C8.29999 8.80001 7.59999 8.53001 7.06999 8.00001L0.549989 1.48001C0.259989 1.19001 0.259989 0.710015 0.549989 0.420015C0.839989 0.130015 1.31999 0.130015 1.60999 0.420015L8.12999 6.94001C8.60999 7.42001 9.38999 7.42001 9.86999 6.94001L16.39 0.420015C16.68 0.130015 17.16 0.130015 17.45 0.420015C17.74 0.710015 17.74 1.19001 17.45 1.48001L10.93 8.00001C10.4 8.53001 9.69999 8.80001 8.99999 8.80001Z" />
    </Svg>
  )
}
