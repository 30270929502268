import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const TriangleDown: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      className={twMerge('h-[5px] w-[8px]', className)}
      viewBox="0 0 8 5"
      {...props}
    >
      <path d="M4 4.52185L0.535898 0.739038L7.4641 0.739038L4 4.52185Z" />
    </Svg>
  )
}
