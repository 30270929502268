import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Upload: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-[59px] w-[53px]', className)} viewBox="0 0 53 59" {...props}>
      <path
        d="M37.2999 7.88984C37.2999 7.69093 37.2209 7.50017 37.0803 7.35951L34.0553 4.33451L31.0303 1.30951C30.8896 1.16886 30.6989 1.08984 30.4999 1.08984H7.44995C4.27431 1.08984 1.69995 3.66421 1.69995 6.83984V40.8398C1.69995 44.0155 4.27432 46.5898 7.44995 46.5898H31.5499C34.7256 46.5898 37.2999 44.0155 37.2999 40.8398V7.88984Z"
        fill="white"
        stroke="#5A36FF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M36.5499 8.74297C36.8532 8.74297 37.1267 8.56024 37.2428 8.27998C37.3588 7.99973 37.2947 7.67714 37.0802 7.46264L30.9271 1.30951C30.7126 1.09502 30.39 1.03085 30.1097 1.14693C29.8295 1.26302 29.6467 1.5365 29.6467 1.83984V4.91641H29.6573C29.6467 4.91645 29.6467 4.91651 29.6467 4.91657L29.6467 4.91706L29.6467 4.91814L29.6467 4.9207L29.6468 4.92738L29.6471 4.94681C29.6474 4.96241 29.6479 4.98324 29.649 5.00882C29.6511 5.05992 29.6551 5.13034 29.6629 5.21605C29.6785 5.3866 29.7093 5.62244 29.7713 5.88988C29.892 6.41025 30.1476 7.12534 30.7114 7.68579C31.2733 8.24445 31.9853 8.49859 32.504 8.61874C32.7704 8.68045 33.0051 8.71123 33.1748 8.72674C33.2601 8.73454 33.3301 8.73859 33.381 8.74069C33.4064 8.74174 33.4272 8.74231 33.4427 8.74262L33.462 8.74291L33.4687 8.74296L33.4713 8.74297L33.4723 8.74297L33.4728 8.74297C33.4731 8.74297 33.4733 8.74297 33.4733 7.99297V8.74297H36.5499Z"
        fill="#9BD9FF"
        stroke="#5A36FF"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle cx="40.0488" cy="45.8398" r="12.9004" fill="#5A36FF" />
      <path
        d="M42.0055 50.8105H38.0935C35.1475 50.8105 33.8875 49.5505 33.8875 46.6045V46.5265C33.8875 43.8625 34.9375 42.5785 37.2895 42.3565C37.5295 42.3385 37.7575 42.5185 37.7815 42.7645C37.8055 43.0105 37.6255 43.2325 37.3735 43.2565C35.4895 43.4305 34.7875 44.3185 34.7875 46.5325V46.6105C34.7875 49.0525 35.6515 49.9165 38.0935 49.9165H42.0055C44.4475 49.9165 45.3115 49.0525 45.3115 46.6105V46.5325C45.3115 44.3065 44.5975 43.4185 42.6775 43.2565C42.4315 43.2325 42.2455 43.0165 42.2695 42.7705C42.2935 42.5245 42.5035 42.3385 42.7555 42.3625C45.1435 42.5665 46.2115 43.8565 46.2115 46.5385V46.6165C46.2115 49.5505 44.9515 50.8105 42.0055 50.8105Z"
        fill="white"
      />
      <path
        d="M40.0491 46.9121C39.8031 46.9121 39.5991 46.7081 39.5991 46.4621V39.6341C39.5991 39.3881 39.8031 39.1841 40.0491 39.1841C40.2951 39.1841 40.4991 39.3881 40.4991 39.6341V46.4621C40.4991 46.7081 40.2951 46.9121 40.0491 46.9121Z"
        fill="white"
      />
      <path
        d="M42.0591 41.4222C41.9451 41.4222 41.8311 41.3802 41.7411 41.2902L40.0491 39.5982L38.3571 41.2902C38.1831 41.4642 37.8951 41.4642 37.7211 41.2902C37.5471 41.1162 37.5471 40.8282 37.7211 40.6542L39.7311 38.6442C39.9051 38.4702 40.1931 38.4702 40.3671 38.6442L42.3771 40.6542C42.5511 40.8282 42.5511 41.1162 42.3771 41.2902C42.2931 41.3802 42.1731 41.4222 42.0591 41.4222Z"
        fill="white"
      />
    </Svg>
  )
}
