import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const FontSize: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[19px] w-[18px]', className)}
      viewBox="0 0 18 19"
      {...props}
    >
      <path
        id="Vector 77"
        d="M7.09766 5.99144V3.83203H16.1252V5.99144"
        stroke="#9CA3AF"
        stroke-width="1.25"
      />
      <path id="Vector 78" d="M11.5684 3.83203V14.6591" stroke="#9CA3AF" stroke-width="1.25" />
      <path id="Vector 79" d="M9.16797 14.6895H13.9667" stroke="#9CA3AF" stroke-width="1.25" />
      <path id="Vector 80" d="M1.88086 9.32031H7.27938" stroke="#9CA3AF" stroke-width="1.25" />
      <path id="Vector 81" d="M4.66797 9.32031V15.1687" stroke="#9CA3AF" stroke-width="1.25" />
    </Svg>
  )
}
