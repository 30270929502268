import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'
export const ChevronThinLeft: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      viewBox="0 0 5 9"
      className={twMerge('h-[9px] w-[5px]', className)}
      {...props}
    >
      <path d="M4.50014 8.83477C4.40514 8.83477 4.31014 8.79977 4.23514 8.72477L0.975137 5.46477C0.445137 4.93477 0.445137 4.06477 0.975137 3.53477L4.23514 0.274766C4.38014 0.129766 4.62014 0.129766 4.76514 0.274766C4.91014 0.419766 4.91014 0.659766 4.76514 0.804766L1.50514 4.06477C1.26514 4.30477 1.26514 4.69477 1.50514 4.93477L4.76514 8.19477C4.91014 8.33977 4.91014 8.57977 4.76514 8.72477C4.69014 8.79477 4.59514 8.83477 4.50014 8.83477Z" />
    </Svg>
  )
}
