import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

// 0 0 21 20
export const ShopifyLogo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 21" className={twMerge('h-5 w-5', className)} {...props}>
      <g clipPath="url(#clip0_5018_174155)">
        <path
          d="M17.1247 4.62333C17.1247 4.498 16.9993 4.498 16.874 4.498C16.7487 4.498 15.25 4.37267 15.25 4.37267C15.25 4.37267 14.25 3.37533 14.1247 3.25C14.1247 3.25 14.1247 3.25 13.9993 3.25L13.25 20.7487L19.25 19.498C19.25 19.3753 17.1247 4.74867 17.1247 4.62333Z"
          fill="currentColor"
        />
        <path
          d="M13.7512 3.12333C13.7512 3.12333 13.5006 3.12333 13.1272 3.24867C12.7512 2.24867 12.1272 1.374 11.0019 1.374H10.8766C10.6259 0.872667 10.2526 0.75 9.87657 0.75C7.25257 0.75 6.00191 4.00067 5.62591 5.62467C4.62591 5.87533 4.00191 6.126 3.87657 6.126C3.25257 6.37667 3.25257 6.37667 3.25257 6.87533C3.25257 7.25133 1.75391 18.6247 1.75391 18.6247L13.0046 20.75L13.7512 3.12333ZM10.8766 3.87267C10.2526 4.24867 9.50057 4.374 8.87657 4.622C9.25257 3.12067 10.0019 2.37133 10.6259 2.12067C10.7512 2.622 10.8766 3.12333 10.8766 3.87267ZM9.75124 1.49933C9.87657 1.49933 10.0019 1.49933 10.1272 1.62467C9.37791 2.00067 8.37791 2.87533 8.00191 4.87533C7.50057 5.00067 7.00191 5.25133 6.50057 5.37667C6.87657 3.87267 7.99924 1.49933 9.75124 1.49933ZM10.2499 10.1233C10.2499 10.1233 9.50057 9.74733 8.75124 9.74733C7.50057 9.74733 7.50057 10.4967 7.50057 10.7473C7.50057 11.7473 10.2499 12.1233 10.2499 14.622C10.2499 16.4967 8.99924 17.7473 7.37524 17.7473C5.37524 17.7473 4.37524 16.4967 4.37524 16.4967L4.87657 14.7473C4.87657 14.7473 5.87657 15.622 6.75124 15.622C7.37524 15.622 7.50057 15.1207 7.50057 14.8727C7.50057 13.4967 5.24991 13.4967 5.24991 11.2487C5.24991 9.374 6.50057 7.62467 9.24991 7.62467C10.2499 7.62467 10.7486 7.87533 10.7486 7.87533L10.2499 10.1233ZM11.5006 3.74733V3.622C11.5006 2.998 11.3752 2.49667 11.2499 2.12067C11.8739 2.12067 12.2499 2.87 12.5006 3.622C12.1246 3.622 11.8766 3.74733 11.5006 3.74733Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5018_174155">
          <rect width="20" height="20" fill="white" transform="translate(0.5 0.75)" />
        </clipPath>
      </defs>
    </Svg>
  )
}
