import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Box: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 31 30"
      stroke="currentColor"
      strokeWidth={1}
      className={twMerge('h-[30px] w-[31px]', className)}
      {...props}
    >
      <path
        d="M15.7499 16.6251C15.5874 16.6251 15.4249 16.5876 15.2749 16.5001L4.23736 10.1126C3.79986 9.85007 3.63736 9.27507 3.89986 8.82507C4.16236 8.37507 4.73736 8.22507 5.18736 8.48757L15.7499 14.6001L26.2499 8.52507C26.6999 8.26257 27.2749 8.42507 27.5374 8.86257C27.7999 9.31257 27.6374 9.88757 27.1999 10.1501L16.2374 16.5001C16.0749 16.5751 15.9124 16.6251 15.7499 16.6251Z"
        fill="#F24072"
      />
      <path
        d="M15.75 27.9498C15.2375 27.9498 14.8125 27.5248 14.8125 27.0123V15.6748C14.8125 15.1623 15.2375 14.7373 15.75 14.7373C16.2625 14.7373 16.6875 15.1623 16.6875 15.6748V27.0123C16.6875 27.5248 16.2625 27.9498 15.75 27.9498Z"
        fill="#F24072"
      />
      <path
        d="M15.7503 28.4375C14.6503 28.4375 13.5628 28.2 12.7003 27.725L6.02529 24.0125C4.21279 23.0125 2.80029 20.6 2.80029 18.525V11.4625C2.80029 9.3875 4.21279 6.9875 6.02529 5.975L12.7003 2.275C14.4128 1.325 17.0878 1.325 18.8003 2.275L25.4753 5.9875C27.2878 6.9875 28.7003 9.4 28.7003 11.475V18.5375C28.7003 20.6125 27.2878 23.0125 25.4753 24.025L18.8003 27.725C17.9378 28.2 16.8503 28.4375 15.7503 28.4375ZM15.7503 3.4375C14.9628 3.4375 14.1878 3.6 13.6128 3.9125L6.93779 7.625C5.73779 8.2875 4.67529 10.0875 4.67529 11.4625V18.525C4.67529 19.9 5.73779 21.7 6.93779 22.375L13.6128 26.0875C14.7503 26.725 16.7503 26.725 17.8878 26.0875L24.5628 22.375C25.7628 21.7 26.8253 19.9125 26.8253 18.525V11.4625C26.8253 10.0875 25.7628 8.2875 24.5628 7.6125L17.8878 3.9C17.3128 3.6 16.5378 3.4375 15.7503 3.4375Z"
        fill="#F24072"
      />
    </Svg>
  )
}
