import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Minus: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-[3px] w-[9px]', className)} viewBox="0 0 9 3" {...props}>
      <path d="M0.5 2.49206V0.508057H8.596V2.49206H0.5Z" fill="currentColor" />
    </Svg>
  )
}
