import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props } from '@/components/shared/common/Svg'

export const ShareUp: React.FC<Props> = ({ className, ...props }) => {
  return (
    <svg viewBox="0 0 16 19" fill="currentColor" className={twMerge('', className)} {...props}>
      <path d="M7.36049 0.388601C7.71194 0.037133 8.28182 0.037133 8.63327 0.388601L11.3333 3.0886C11.6847 3.44008 11.6847 4.00992 11.3333 4.3614C10.9818 4.71287 10.4119 4.71287 10.0605 4.3614L8.89688 3.19779V11.825C8.89688 12.3221 8.49395 12.725 7.99688 12.725C7.49981 12.725 7.09688 12.3221 7.09688 11.825V3.19779L5.93327 4.3614C5.5818 4.71287 5.01195 4.71287 4.66048 4.3614C4.30901 4.00992 4.30901 3.44008 4.66048 3.0886L7.36049 0.388601ZM0.796875 8.225C0.796875 7.23089 1.60276 6.425 2.59688 6.425H4.39688C4.89393 6.425 5.29688 6.82795 5.29688 7.325C5.29688 7.82207 4.89393 8.225 4.39688 8.225H2.59688V16.325H13.3969V8.225H11.5969C11.0998 8.225 10.6969 7.82207 10.6969 7.325C10.6969 6.82795 11.0998 6.425 11.5969 6.425H13.3969C14.391 6.425 15.1969 7.23089 15.1969 8.225V16.325C15.1969 17.3191 14.391 18.125 13.3969 18.125H2.59688C1.60276 18.125 0.796875 17.3191 0.796875 16.325V8.225Z" />
    </svg>
  )
}
