import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

// 0 0 21 20
export const ReactLogo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 21" className={twMerge('h-5 w-5', className)} {...props}>
      <g clipPath="url(#clip0_5018_174164)">
        <path
          d="M10.4988 14.402C15.7815 14.402 20.064 12.7669 20.064 10.7498C20.064 8.73279 15.7815 7.09766 10.4988 7.09766C5.21609 7.09766 0.933594 8.73279 0.933594 10.7498C0.933594 12.7669 5.21609 14.402 10.4988 14.402Z"
          stroke="currentColor"
        />
        <path
          d="M7.33576 12.5765C9.97713 17.1515 13.5344 20.0427 15.2812 19.0342C17.0281 18.0256 16.3029 13.4993 13.6615 8.92435C11.0202 4.34937 7.46284 1.45819 5.71603 2.46671C3.96922 3.47523 4.6944 8.00155 7.33576 12.5765Z"
          stroke="currentColor"
        />
        <path
          d="M7.33695 8.92452C4.69559 13.4995 3.97041 18.0258 5.71722 19.0343C7.46403 20.0428 11.0213 17.1517 13.6627 12.5767C16.3041 8.00172 17.0292 3.4754 15.2824 2.46688C13.5356 1.45836 9.97831 4.34954 7.33695 8.92452Z"
          stroke="currentColor"
        />
        <path
          d="M10.4975 12.534C11.482 12.534 12.2801 11.7359 12.2801 10.7514C12.2801 9.76685 11.482 8.96875 10.4975 8.96875C9.51294 8.96875 8.71484 9.76685 8.71484 10.7514C8.71484 11.7359 9.51294 12.534 10.4975 12.534Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5018_174164">
          <rect width="20" height="20" fill="white" transform="translate(0.5 0.75)" />
        </clipPath>
      </defs>
    </Svg>
  )
}
