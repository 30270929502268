import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Cross: React.FC<Props> = ({ className, ...props }: Props) => {
  return (
    <Svg
      viewBox="0 0 14 14"
      stroke="currentColor"
      className={twMerge('h-4 w-4', className)}
      {...props}
    >
      <path
        d="M2.93135 3.64147L10.356 11.0661C10.5739 11.284 10.9335 11.284 11.1515 11.0661C11.3694 10.8482 11.3694 10.4885 11.1515 10.2706L3.72685 2.84597C3.50892 2.62804 3.14929 2.62804 2.93135 2.84597C2.71342 3.0639 2.71342 3.42353 2.93135 3.64147Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
      <path
        d="M3.76109 11.1897L11.1857 3.76512C11.4036 3.54719 11.4036 3.18756 11.1857 2.96962C10.9678 2.75169 10.6082 2.75169 10.3902 2.96962L2.9656 10.3942C2.74767 10.6122 2.74767 10.9718 2.9656 11.1897C3.18353 11.4077 3.54316 11.4077 3.76109 11.1897Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </Svg>
  )
}
