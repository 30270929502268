import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Tone: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 13 12" className={twMerge('h-4 w-4', className)} {...props}>
      <path
        d="M1.73573 9.428C1.30791 9.428 0.953125 9.15821 0.953125 8.83288V3.16738C0.953125 2.84205 1.30791 2.57227 1.73573 2.57227C2.16356 2.57227 2.51834 2.84205 2.51834 3.16738V8.83288C2.51834 9.16615 2.16356 9.428 1.73573 9.428Z"
        fill="currentColor"
      />
      <path
        d="M6.17323 12C5.74541 12 5.39062 11.6452 5.39062 11.2174V0.782609C5.39062 0.354783 5.74541 0 6.17323 0C6.60106 0 6.95584 0.354783 6.95584 0.782609V11.2174C6.95584 11.6452 6.60106 12 6.17323 12Z"
        fill="currentColor"
      />
      <path
        d="M10.6029 9.428C10.1751 9.428 9.82031 9.15821 9.82031 8.83288V3.16738C9.82031 2.84205 10.1751 2.57227 10.6029 2.57227C11.0307 2.57227 11.3855 2.84205 11.3855 3.16738V8.83288C11.3855 9.16615 11.0307 9.428 10.6029 9.428Z"
        fill="currentColor"
      />
    </Svg>
  )
}
