import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Click: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M12.5324 19.0527C12.5216 19.0527 12.5216 19.0527 12.5108 19.0527C11.3885 19.0419 10.4388 18.3405 10.1042 17.2721L7.99981 10.4948C7.71923 9.57745 7.95664 8.59538 8.63653 7.92628C9.30563 7.25718 10.2769 7.01974 11.1834 7.30033L17.9715 9.40477C19.0507 9.73932 19.7522 10.689 19.763 11.8114C19.7738 12.9337 19.0939 13.8834 18.0255 14.2396L16.1693 14.8655C15.8887 14.9626 15.6837 15.1676 15.5865 15.4374L14.9498 17.3045C14.5937 18.3728 13.644 19.0527 12.5324 19.0527ZM10.4064 8.80042C10.0826 8.80042 9.86681 8.97309 9.76969 9.07022C9.52147 9.31843 9.43513 9.67456 9.54305 10.0091L11.6475 16.7864C11.8309 17.38 12.3597 17.4339 12.5216 17.4339C12.6511 17.4232 13.2015 17.3908 13.3958 16.7972L14.0325 14.9302C14.2807 14.1856 14.885 13.5813 15.6297 13.333L17.4859 12.7071C17.4859 12.7071 17.4859 12.7071 17.4967 12.7071C18.0903 12.5128 18.1334 11.984 18.1334 11.8329C18.1334 11.6711 18.0794 11.1423 17.4859 10.9588L10.6978 8.85437C10.5899 8.8112 10.4927 8.80042 10.4064 8.80042Z"
        fill="currentColor"
      />
      <path
        d="M10.5005 4.44314V1.20557"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M6.42091 5.85055L3.74268 3.03613"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M5.36208 10L2.12451 10"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </Svg>
  )
}
