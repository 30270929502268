;<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
></svg>

import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const BorderWidthStyle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[18px] w-[18px]', className)}
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M1.5 9.96677C1.5 10.3661 1.82671 10.6928 2.22602 10.6928C2.62533 10.6928 2.95204 10.3661 2.95204 9.96677V8.03071C2.95204 7.6314 2.62533 7.30469 2.22602 7.30469C1.82671 7.30469 1.5 7.6314 1.5 8.03071V9.96677Z"
        fill="#9CA3AF"
      />
      <path
        d="M16.5048 8.03071C16.5048 7.6314 16.1781 7.30469 15.7788 7.30469C15.3794 7.30469 15.0527 7.6314 15.0527 8.03071V9.96677C15.0527 10.3661 15.3794 10.6928 15.7788 10.6928C16.1781 10.6928 16.5048 10.3661 16.5048 9.96677V8.03071Z"
        fill="#9CA3AF"
      />
      <path
        d="M16.2245 13.4569C16.1229 13.2924 15.9631 13.1762 15.772 13.1326C15.5832 13.0891 15.3896 13.1205 15.225 13.2222C15.0605 13.3238 14.9443 13.486 14.9007 13.6747C14.7555 14.2943 14.2836 14.7735 13.6665 14.9283L13.6375 14.9356C13.3834 15.0106 13.1196 15.0493 12.8558 15.0493H5.15269C4.8889 15.0493 4.62511 15.0106 4.371 14.9356L4.34196 14.9283C3.72726 14.7735 3.25293 14.2943 3.11015 13.6747C3.06659 13.486 2.95042 13.3262 2.78586 13.2222C2.66969 13.1496 2.53901 13.1133 2.40591 13.1133C2.35024 13.1133 2.29458 13.1205 2.23892 13.1326C2.05016 13.1762 1.89043 13.2924 1.78637 13.4569C1.68472 13.6215 1.65326 13.8175 1.69682 14.0039C1.96545 15.1461 2.83668 16.0391 3.97169 16.3296C4.36132 16.4433 4.75822 16.499 5.15511 16.499H12.8582C13.2551 16.499 13.652 16.4433 14.0344 16.332C15.1766 16.0367 16.0503 15.1461 16.3165 14.0039C16.36 13.8151 16.3286 13.6215 16.2269 13.4569H16.2245Z"
        fill="#9CA3AF"
      />
      <path
        d="M1.78246 4.54203C1.8841 4.7066 2.04383 4.82276 2.23501 4.86632C2.42378 4.9123 2.61739 4.87842 2.78195 4.77678C2.94652 4.67513 3.06268 4.51299 3.10624 4.32422C3.25144 3.70469 3.72336 3.22551 4.34048 3.07063L4.36952 3.06337C4.62363 2.98834 4.88741 2.94962 5.1512 2.94962H12.8543C13.1181 2.94962 13.3819 2.98834 13.636 3.06337L13.665 3.07063C14.2797 3.22551 14.754 3.70469 14.8968 4.32422C14.9743 4.65335 15.2647 4.88568 15.6035 4.88568C15.6592 4.88568 15.7148 4.87842 15.7705 4.86632C15.9592 4.82276 16.119 4.7066 16.223 4.54203C16.3247 4.37747 16.3561 4.18144 16.3126 3.99509C16.0439 2.85282 15.1727 1.95981 14.0377 1.6694C13.6481 1.55566 13.2512 1.5 12.8543 1.5H5.1512C4.75431 1.5 4.35742 1.55566 3.97505 1.66699C2.83277 1.96223 1.95913 2.85282 1.69292 3.99509C1.64936 4.18386 1.68082 4.37747 1.78246 4.54203Z"
        fill="#9CA3AF"
      />
    </Svg>
  )
}
