import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ChevronUp: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg stroke="currentcolor" className={twMerge('h-5 w-5 text-gray-400', className)} {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth}
        d="M5 15l7-7 7 7"
      />
    </Svg>
  )
}
