import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const PaddingStyle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[19px] w-[20px]', className)}
      viewBox="0 0 20 19"
      {...props}
    >
      <mask id="path-1-inside-1_946_971373" fill="white">
        <rect x="6.00391" y="5.5" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="6.00391"
        y="5.5"
        width="8"
        height="8"
        rx="1"
        stroke="#9CA3AF"
        stroke-width="2.5"
        mask="url(#path-1-inside-1_946_971373)"
      />
      <path d="M2.12891 2.5H17.875" stroke="#9CA3AF" stroke-width="1.25" />
      <path d="M2.12891 16.5H17.875" stroke="#9CA3AF" stroke-width="1.25" />
    </Svg>
  )
}
