import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const MouseCircle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 31 30"
      stroke="currentColor"
      strokeWidth={1}
      className={twMerge('h-5 w-5', className)}
      {...props}
    >
      <path
        d="M20.9625 28.4626C20.95 28.4626 20.95 28.4626 20.9375 28.4626C19.675 28.4501 18.625 27.6751 18.25 26.4626L15.9375 19.0251C15.625 18.0001 15.8875 16.9001 16.65 16.1626C17.4 15.4251 18.4875 15.1501 19.4875 15.4626L26.9375 17.7751C28.1375 18.1501 28.925 19.2001 28.9375 20.4626C28.95 21.7126 28.1875 22.7751 26.9875 23.1751L24.95 23.8626C24.6625 23.9626 24.4375 24.1751 24.35 24.4626L23.65 26.5126C23.2625 27.7001 22.2125 28.4626 20.9625 28.4626ZM18.6375 17.2126C18.3 17.2126 18.075 17.4001 17.975 17.4876C17.7125 17.7501 17.625 18.1126 17.7375 18.4751L20.05 25.9126C20.25 26.5501 20.8 26.5751 20.975 26.5876C21.15 26.5876 21.6875 26.5376 21.8875 25.9251L22.5875 23.8751C22.8625 23.0376 23.5375 22.3751 24.375 22.0876L26.4125 21.4001C27.0375 21.2001 27.075 20.6501 27.075 20.4876C27.075 20.3251 27.025 19.7751 26.4 19.5751L18.95 17.2626C18.825 17.2251 18.725 17.2126 18.6375 17.2126Z"
        fill="#FED023"
      />
      <path
        d="M15.5 28.4375C8.0875 28.4375 2.0625 22.4125 2.0625 15C2.0625 7.5875 8.0875 1.5625 15.5 1.5625C22.9125 1.5625 28.9375 7.5875 28.9375 15C28.9375 15.5125 28.5125 15.9375 28 15.9375C27.4875 15.9375 27.0625 15.5125 27.0625 15C27.0625 8.625 21.875 3.4375 15.5 3.4375C9.125 3.4375 3.9375 8.625 3.9375 15C3.9375 21.375 9.125 26.5625 15.5 26.5625C16.0125 26.5625 16.4375 26.9875 16.4375 27.5C16.4375 28.0125 16.0125 28.4375 15.5 28.4375Z"
        fill="#FED023"
      />
    </Svg>
  )
}
