import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ArrowLeft: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M12.7017 17.1333L12.7016 17.1334L12.6986 17.1303L7.26612 11.6978C7.26597 11.6977 7.26582 11.6975 7.26567 11.6974C6.80781 11.2481 6.56198 10.6408 6.56198 10.0003C6.56198 9.3585 6.81701 8.75178 7.26526 8.30354L12.6986 2.87021C12.9891 2.57972 13.4682 2.57972 13.7587 2.87021C14.0492 3.16069 14.0492 3.63983 13.7587 3.93032L8.32537 9.36365C7.97418 9.71483 7.97418 10.2857 8.32537 10.6369L13.7587 16.0702C14.0492 16.3607 14.0492 16.8398 13.7587 17.1303C13.609 17.28 13.4183 17.3503 13.2286 17.3503C13.0353 17.3503 12.8472 17.2692 12.7017 17.1333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </Svg>
  )
}
