import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Paragraph: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      stroke="currentColor"
      className={twMerge('h-[23px] w-[25px]', className)}
      viewBox="0 0 25 23"
      {...props}
    >
      <path d="M24.5 0.205872V1.61764H11.0882V0.205872H24.5ZM11.0882 10.0882H24.5V8.67646H11.0882V10.0882ZM0.5 18.5588H24.5V17.147H0.5V18.5588ZM0.5 14.3235H24.5V12.9118H0.5V14.3235ZM11.0882 5.85293H24.5V4.44117H11.0882V5.85293ZM0.5 22.7941H21.6765V21.3823H0.5V22.7941ZM1.91176 1.61764H4.02941V8.67646H2.61765V10.0882H6.85294V8.67646H5.44118V1.61764H7.55882V2.32352H8.97059V0.205872H0.5V2.32352H1.91176V1.61764Z" />
    </Svg>
  )
}
