import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const BoxDotted: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 20 20" className={twMerge('h-5 w-5', className)} {...props}>
      <rect
        x="1.17188"
        y="1"
        width="18"
        height="18"
        stroke="#4B5563"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2.5"
      />
    </Svg>
  )
}
