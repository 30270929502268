import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ArrowRight: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M8.61081 17.1333L8.61086 17.1334L8.61391 17.1303L14.0464 11.6978C14.0465 11.6977 14.0467 11.6975 14.0468 11.6974C14.5047 11.2481 14.7505 10.6408 14.7505 10.0003C14.7505 9.3585 14.4955 8.75178 14.0472 8.30354L8.61391 2.87021C8.32343 2.57972 7.84428 2.57972 7.5538 2.87021C7.26332 3.16069 7.26332 3.63983 7.5538 3.93032L12.9871 9.36365C13.3383 9.71483 13.3383 10.2857 12.9871 10.6369L7.5538 16.0702C7.26332 16.3607 7.26332 16.8398 7.5538 17.1303C7.70348 17.28 7.89416 17.3503 8.08385 17.3503C8.27723 17.3503 8.46526 17.2692 8.61081 17.1333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </Svg>
  )
}
