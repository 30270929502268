import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const TeamSwitcher: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg fill="currentColor" className={twMerge('h-6 w-6 text-gray-700', className)} {...props}>
      <path d="M13 22.75H5C2.58 22.75 1.25 21.42 1.25 19V11C1.25 8.58 2.58 7.25 5 7.25H10C10.41 7.25 10.75 7.59 10.75 8V19C10.75 20.58 11.42 21.25 13 21.25C13.41 21.25 13.75 21.59 13.75 22C13.75 22.41 13.41 22.75 13 22.75ZM5 8.75C3.42 8.75 2.75 9.42 2.75 11V19C2.75 20.58 3.42 21.25 5 21.25H9.79999C9.43999 20.66 9.25 19.91 9.25 19V8.75H5Z" />
      <path d="M10 8.75H5C4.59 8.75 4.25 8.41 4.25 8V6C4.25 4.48 5.48 3.25 7 3.25H10.11C10.34 3.25 10.56 3.35998 10.7 3.53998C10.84 3.72998 10.89 3.97 10.83 4.19C10.77 4.41 10.75 4.66 10.75 5V8C10.75 8.41 10.41 8.75 10 8.75ZM5.75 7.25H9.25V5C9.25 4.91 9.25 4.83 9.25 4.75H7C6.31 4.75 5.75 5.31 5.75 6V7.25Z" />
      <path d="M14 13.75C13.59 13.75 13.25 13.41 13.25 13V8C13.25 7.59 13.59 7.25 14 7.25C14.41 7.25 14.75 7.59 14.75 8V13C14.75 13.41 14.41 13.75 14 13.75Z" />
      <path d="M18 13.75C17.59 13.75 17.25 13.41 17.25 13V8C17.25 7.59 17.59 7.25 18 7.25C18.41 7.25 18.75 7.59 18.75 8V13C18.75 13.41 18.41 13.75 18 13.75Z" />
      <path d="M18 22.75H14C13.59 22.75 13.25 22.41 13.25 22V18C13.25 17.04 14.04 16.25 15 16.25H17C17.96 16.25 18.75 17.04 18.75 18V22C18.75 22.41 18.41 22.75 18 22.75ZM14.75 21.25H17.25V18C17.25 17.86 17.14 17.75 17 17.75H15C14.86 17.75 14.75 17.86 14.75 18V21.25Z" />
      <path d="M6 17.75C5.59 17.75 5.25 17.41 5.25 17V13C5.25 12.59 5.59 12.25 6 12.25C6.41 12.25 6.75 12.59 6.75 13V17C6.75 17.41 6.41 17.75 6 17.75Z" />
      <path d="M19 22.75H13C10.58 22.75 9.25 21.42 9.25 19V5C9.25 2.58 10.58 1.25 13 1.25H19C21.42 1.25 22.75 2.58 22.75 5V19C22.75 21.42 21.42 22.75 19 22.75ZM13 2.75C11.42 2.75 10.75 3.42 10.75 5V19C10.75 20.58 11.42 21.25 13 21.25H19C20.58 21.25 21.25 20.58 21.25 19V5C21.25 3.42 20.58 2.75 19 2.75H13Z" />
    </Svg>
  )
}
