import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Video: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 19 18" className={twMerge('h-4 w-4', className)} {...props}>
      <path
        d="M11.4219 17.0625H6.92188C2.84938 17.0625 1.10938 15.3225 1.10938 11.25V6.75C1.10938 2.6775 2.84938 0.9375 6.92188 0.9375H11.4219C15.4944 0.9375 17.2344 2.6775 17.2344 6.75V11.25C17.2344 15.3225 15.4944 17.0625 11.4219 17.0625ZM6.92188 2.0625C3.46438 2.0625 2.23438 3.2925 2.23438 6.75V11.25C2.23438 14.7075 3.46438 15.9375 6.92188 15.9375H11.4219C14.8794 15.9375 16.1094 14.7075 16.1094 11.25V6.75C16.1094 3.2925 14.8794 2.0625 11.4219 2.0625H6.92188Z"
        fill="currentColor"
      />
      <path
        d="M8.24188 12.2775C7.92688 12.2775 7.63438 12.2025 7.37188 12.0525C6.77188 11.7075 6.42688 11.0025 6.42688 10.11V7.88998C6.42688 7.00498 6.77188 6.29248 7.37188 5.94748C7.97188 5.60248 8.75188 5.65498 9.52438 6.10498L11.4519 7.21498C12.2169 7.65748 12.6594 8.30998 12.6594 8.99998C12.6594 9.68998 12.2169 10.3425 11.4519 10.785L9.52438 11.895C9.08938 12.15 8.64688 12.2775 8.24188 12.2775ZM8.24938 6.84748C8.12938 6.84748 8.02438 6.86998 7.94188 6.92248C7.70188 7.06498 7.55938 7.41748 7.55938 7.88998V10.11C7.55938 10.5825 7.69438 10.935 7.94188 11.0775C8.18188 11.22 8.55688 11.16 8.96938 10.92L10.8969 9.80998C11.3094 9.56998 11.5419 9.27748 11.5419 8.99998C11.5419 8.72248 11.3094 8.42248 10.8969 8.18998L8.96938 7.07998C8.69938 6.92248 8.45188 6.84748 8.24938 6.84748Z"
        fill="currentColor"
      />
    </Svg>
  )
}
