import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const GoalFlowArrow: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg fill="currentColor" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M5.46967 20.5303C5.76256 20.8232 6.23744 20.8232 6.53033 20.5303L11.3033 15.7574C11.5962 15.4645 11.5962 14.9896 11.3033 14.6967C11.0104 14.4038 10.5355 14.4038 10.2426 14.6967L6 18.9393L1.75736 14.6967C1.46447 14.4038 0.989592 14.4038 0.696699 14.6967C0.403805 14.9896 0.403805 15.4645 0.696698 15.7574L5.46967 20.5303ZM5.25 -3.27836e-08L5.25 20L6.75 20L6.75 3.27836e-08L5.25 -3.27836e-08Z"
        fill="#5A36FF"
      />
    </Svg>
  )
}
