import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const People: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[21px] w-[21px]', className)}
      viewBox="0 0 21 21"
      {...props}
    >
      <path d="M15.5004 7.09166C15.4754 7.09166 15.4588 7.09166 15.4338 7.09166H15.3921C13.8171 7.04166 12.6421 5.82499 12.6421 4.32499C12.6421 2.79166 13.8921 1.54999 15.4171 1.54999C16.9421 1.54999 18.1921 2.79999 18.1921 4.32499C18.1838 5.83332 17.0088 7.04999 15.5088 7.09999C15.5088 7.09166 15.5088 7.09166 15.5004 7.09166ZM15.4171 2.79166C14.5754 2.79166 13.8921 3.47499 13.8921 4.31666C13.8921 5.14166 14.5338 5.80833 15.3588 5.84166C15.3671 5.83333 15.4338 5.83333 15.5088 5.84166C16.3171 5.8 16.9421 5.13333 16.9504 4.31666C16.9504 3.47499 16.2671 2.79166 15.4171 2.79166Z" />
      <path d="M15.5087 13.2334C15.1837 13.2334 14.8587 13.2084 14.5337 13.15C14.1921 13.0917 13.9671 12.7667 14.0254 12.425C14.0837 12.0834 14.4087 11.8584 14.7504 11.9167C15.7754 12.0917 16.8587 11.9 17.5837 11.4167C17.9754 11.1584 18.1837 10.8334 18.1837 10.5084C18.1837 10.1834 17.9671 9.8667 17.5837 9.60837C16.8587 9.12504 15.7587 8.93337 14.7254 9.11671C14.3837 9.18337 14.0587 8.95004 14.0004 8.60837C13.9421 8.26671 14.1671 7.94171 14.5087 7.88338C15.8671 7.64171 17.2754 7.90004 18.2754 8.5667C19.0087 9.05837 19.4337 9.75837 19.4337 10.5084C19.4337 11.25 19.0171 11.9584 18.2754 12.4584C17.5171 12.9584 16.5337 13.2334 15.5087 13.2334Z" />
      <path d="M5.47539 7.09169C5.46706 7.09169 5.45872 7.09169 5.45872 7.09169C3.95872 7.04169 2.78372 5.82502 2.77539 4.32502C2.77539 2.79168 4.02539 1.54169 5.55039 1.54169C7.07539 1.54169 8.32539 2.79169 8.32539 4.31669C8.32539 5.82502 7.15039 7.04169 5.65039 7.09169L5.47539 6.46669L5.53373 7.09169C5.51706 7.09169 5.49206 7.09169 5.47539 7.09169ZM5.55872 5.84169C5.60872 5.84169 5.65039 5.84169 5.70039 5.85002C6.44206 5.81669 7.09206 5.15002 7.09206 4.32502C7.09206 3.48335 6.40873 2.80002 5.56706 2.80002C4.72539 2.80002 4.04206 3.48335 4.04206 4.32502C4.04206 5.14168 4.67539 5.80002 5.48372 5.85002C5.49206 5.84169 5.52539 5.84169 5.55872 5.84169Z" />
      <path d="M5.4665 13.2334C4.4415 13.2334 3.45817 12.9584 2.69984 12.4584C1.9665 11.9667 1.5415 11.2584 1.5415 10.5084C1.5415 9.76671 1.9665 9.05837 2.69984 8.5667C3.69984 7.90004 5.10817 7.64171 6.4665 7.88338C6.80817 7.94171 7.03317 8.26671 6.97484 8.60837C6.9165 8.95004 6.59151 9.18337 6.24984 9.11671C5.21651 8.93337 4.12484 9.12504 3.39151 9.60837C2.99984 9.8667 2.7915 10.1834 2.7915 10.5084C2.7915 10.8334 3.00817 11.1584 3.39151 11.4167C4.11651 11.9 5.19984 12.0917 6.22484 11.9167C6.5665 11.8584 6.89151 12.0917 6.94984 12.425C7.00817 12.7667 6.78317 13.0917 6.4415 13.15C6.11651 13.2084 5.7915 13.2334 5.4665 13.2334Z" />
      <path d="M10.5004 13.3166C10.4754 13.3166 10.4588 13.3166 10.4338 13.3166H10.3921C8.81709 13.2666 7.64209 12.05 7.64209 10.55C7.64209 9.01663 8.89209 7.77496 10.4171 7.77496C11.9421 7.77496 13.1921 9.02496 13.1921 10.55C13.1838 12.0583 12.0088 13.275 10.5088 13.325C10.5088 13.3166 10.5088 13.3166 10.5004 13.3166ZM10.4171 9.01663C9.57542 9.01663 8.89209 9.69997 8.89209 10.5416C8.89209 11.3666 9.53376 12.0333 10.3588 12.0666C10.3671 12.0583 10.4338 12.0583 10.5088 12.0666C11.3171 12.025 11.9421 11.3583 11.9504 10.5416C11.9504 9.7083 11.2671 9.01663 10.4171 9.01663Z" />
      <path d="M10.4993 19.4667C9.49925 19.4667 8.49925 19.2083 7.72425 18.6833C6.99092 18.1917 6.56592 17.4917 6.56592 16.7417C6.56592 16 6.98258 15.2833 7.72425 14.7917C9.28258 13.7583 11.7243 13.7583 13.2743 14.7917C14.0076 15.2833 14.4326 15.9833 14.4326 16.7333C14.4326 17.475 14.0159 18.1917 13.2743 18.6833C12.4993 19.2 11.4993 19.4667 10.4993 19.4667ZM8.41592 15.8417C8.02425 16.1 7.81592 16.425 7.81592 16.75C7.81592 17.075 8.03259 17.3917 8.41592 17.65C9.54092 18.4083 11.4493 18.4083 12.5743 17.65C12.9659 17.3917 13.1743 17.0667 13.1743 16.7417C13.1743 16.4167 12.9576 16.1 12.5743 15.8417C11.4576 15.0833 9.54925 15.0917 8.41592 15.8417Z" />
    </Svg>
  )
}
