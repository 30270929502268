import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Tablet: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M12.6706 18.9567H7.67057C3.99557 18.9567 2.87891 17.84 2.87891 14.165V5.83171C2.87891 2.15671 3.99557 1.04004 7.67057 1.04004H12.6706C16.3456 1.04004 17.4622 2.15671 17.4622 5.83171V14.165C17.4622 17.84 16.3456 18.9567 12.6706 18.9567ZM7.67057 2.29004C4.68724 2.29004 4.12891 2.85671 4.12891 5.83171V14.165C4.12891 17.14 4.68724 17.7067 7.67057 17.7067H12.6706C15.6539 17.7067 16.2122 17.14 16.2122 14.165V5.83171C16.2122 2.85671 15.6539 2.29004 12.6706 2.29004H7.67057Z"
        fill="currentColor"
      />
      <circle cx="10.1719" cy="16" r="1" fill="currentColor" />
    </Svg>
  )
}
