import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'
export const ChevronThinRight: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      viewBox="0 0 5 9"
      className={twMerge('h-[9px] w-[5px]', className)}
      {...props}
    >
      <path d="M0.671738 8.83477C0.766738 8.83477 0.861738 8.79977 0.936738 8.72477L4.19674 5.46477C4.72674 4.93477 4.72674 4.06477 4.19674 3.53477L0.936738 0.274766C0.791738 0.129766 0.551738 0.129766 0.406738 0.274766C0.261738 0.419766 0.261738 0.659766 0.406738 0.804766L3.66674 4.06477C3.90674 4.30477 3.90674 4.69477 3.66674 4.93477L0.406738 8.19477C0.261738 8.33977 0.261738 8.57977 0.406738 8.72477C0.481738 8.79477 0.576738 8.83477 0.671738 8.83477Z" />
    </Svg>
  )
}
