import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ChevronRight: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 19 18" className={twMerge('h-[18px] w-[19px]', className)} {...props}>
      <g id="asicon/outline/arrow-right">
        <g id="vuesax/outline/arrow-right">
          <g id="arrow-right">
            <path
              id="Vector"
              d="M7.18172 15.5031C7.03922 15.5031 6.89672 15.4506 6.78422 15.3381C6.56672 15.1206 6.56672 14.7606 6.78422 14.5431L11.6742 9.65313C12.0342 9.29313 12.0342 8.70813 11.6742 8.34812L6.78422 3.45813C6.56672 3.24063 6.56672 2.88063 6.78422 2.66313C7.00172 2.44563 7.36172 2.44563 7.57922 2.66313L12.4692 7.55312C12.8517 7.93562 13.0692 8.45313 13.0692 9.00063C13.0692 9.54813 12.8592 10.0656 12.4692 10.4481L7.57922 15.3381C7.46672 15.4431 7.32422 15.5031 7.18172 15.5031Z"
            />
          </g>
        </g>
      </g>
    </Svg>
  )
}
