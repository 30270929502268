import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Svg, Props as SvgProps } from '@/components/shared/common/Svg'

type Props = SvgProps & {
  size?: 'large' | 'base' | 'sm' | 'xs'
  color?: string
}

export const Loader: React.FC<Props> = ({
  size = 'base',
  color = '#4B5563',
  className,
  ...props
}) => {
  let width = 24
  let height = 36
  if (size === 'large') {
    width = 33
    height = 50
  } else if (size === 'sm') {
    width = 14
    height = 21
  } else if (size === 'xs') {
    width = 12
    height = 18
  }

  return (
    <Svg
      viewBox="0 0 33 50"
      className={twMerge('', className)}
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        d="M23.7898 49.1875H9.20985C4.95735 49.1875 1.85235 47.41 0.659846 44.35C-0.577654 41.155 0.569845 37.195 3.53984 34.5175L13.9798 25L3.53984 15.4825C0.569845 12.805 -0.577654 8.845 0.659846 5.65C1.85235 2.5675 4.95735 0.8125 9.20985 0.8125H23.7898C28.0423 0.8125 31.1473 2.59 32.3398 5.65C33.5773 8.845 32.4298 12.805 29.4598 15.4825L19.0198 25L29.4823 34.5175C32.4298 37.195 33.5998 41.155 32.3623 44.35C31.1473 47.41 28.0423 49.1875 23.7898 49.1875ZM16.4998 27.2725L5.81235 36.9925C3.92235 38.725 3.08985 41.245 3.80985 43.1125C4.48485 44.845 6.39735 45.8125 9.20985 45.8125H23.7898C26.6023 45.8125 28.5148 44.8675 29.1898 43.1125C29.9098 41.245 29.0998 38.725 27.1873 36.9925L16.4998 27.2725ZM9.20985 4.1875C6.39735 4.1875 4.48485 5.1325 3.80985 6.8875C3.08985 8.755 3.89985 11.275 5.81235 13.0075L16.4998 22.7275L27.1873 13.0075C29.0773 11.275 29.9098 8.755 29.1898 6.8875C28.5148 5.155 26.6023 4.1875 23.7898 4.1875H9.20985Z"
        fill={color}
      />
    </Svg>
  )
}
