import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Plus: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-[13px] w-[12px]', className)} viewBox="0 0 12 13" {...props}>
      <path
        d="M5.45499 12.5V7.09883H0.5V5.92466H5.45499V0.5H6.62916V5.92466H11.5841V7.09883H6.62916V12.5H5.45499Z"
        fill="currentColor"
      />
    </Svg>
  )
}
