import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Eye: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-[13px] w-[14px]', className)} viewBox="0 0 14 13" {...props}>
      <path
        d="M6.99995 9.2062C5.5062 9.2062 4.2937 7.9937 4.2937 6.49995C4.2937 5.0062 5.5062 3.7937 6.99995 3.7937C8.4937 3.7937 9.7062 5.0062 9.7062 6.49995C9.7062 7.9937 8.4937 9.2062 6.99995 9.2062ZM6.99995 4.7312C6.02495 4.7312 5.2312 5.52495 5.2312 6.49995C5.2312 7.47495 6.02495 8.2687 6.99995 8.2687C7.97495 8.2687 8.7687 7.47495 8.7687 6.49995C8.7687 5.52495 7.97495 4.7312 6.99995 4.7312Z"
        fill="currentColor"
      />
      <path
        d="M7.00005 12.1378C4.65005 12.1378 2.4313 10.7628 0.906299 8.37529C0.243799 7.34404 0.243799 5.66279 0.906299 4.62529C2.43755 2.23779 4.6563 0.862793 7.00005 0.862793C9.3438 0.862793 11.5625 2.23779 13.0875 4.62529C13.75 5.65654 13.75 7.33779 13.0875 8.37529C11.5625 10.7628 9.3438 12.1378 7.00005 12.1378ZM7.00005 1.80029C4.9813 1.80029 3.05005 3.01279 1.70005 5.13154C1.2313 5.86279 1.2313 7.13779 1.70005 7.86904C3.05005 9.98779 4.9813 11.2003 7.00005 11.2003C9.0188 11.2003 10.95 9.98779 12.3 7.86904C12.7688 7.13779 12.7688 5.86279 12.3 5.13154C10.95 3.01279 9.0188 1.80029 7.00005 1.80029Z"
        fill="currentColor"
      />
    </Svg>
  )
}
