import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Search: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-[18px] w-[19px]', className)} viewBox="0 0 19 18" {...props}>
      <path
        d="M1.1875 8.625C1.1875 13.0006 4.74943 16.5625 9.125 16.5625C13.5006 16.5625 17.0625 13.0006 17.0625 8.625C17.0625 4.24943 13.5006 0.6875 9.125 0.6875C4.74943 0.6875 1.1875 4.24943 1.1875 8.625ZM2.8125 8.625C2.8125 5.14789 5.64076 2.3125 9.125 2.3125C12.6092 2.3125 15.4375 5.14789 15.4375 8.625C15.4375 12.1021 12.6092 14.9375 9.125 14.9375C5.64076 14.9375 2.8125 12.1021 2.8125 8.625Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M16.4257 17.0744C16.5875 17.2362 16.7947 17.3126 17 17.3126C17.2052 17.3126 17.4124 17.2362 17.5742 17.0744C17.8894 16.7592 17.8894 16.2409 17.5742 15.9258L16.0742 14.4258C15.7591 14.1107 15.2408 14.1107 14.9257 14.4258C14.6105 14.7409 14.6105 15.2592 14.9257 15.5744L16.4257 17.0744Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </Svg>
  )
}
