import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

// 0 0 21 20
export const NextjsLogo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 21" className={twMerge('h-5 w-5', className)} {...props}>
      <g clipPath="url(#clip0_5018_174167)">
        <path
          d="M9.84674 0.765143C9.80378 0.769049 9.66707 0.782719 9.54404 0.792484C6.70643 1.04832 4.04848 2.57942 2.36505 4.9327C1.42764 6.24117 0.828094 7.7254 0.601554 9.29751C0.521483 9.84629 0.511719 10.0084 0.511719 10.7524C0.511719 11.4965 0.521483 11.6586 0.601554 12.2074C1.14447 15.959 3.81413 19.111 7.43487 20.2789C8.08324 20.4878 8.76677 20.6304 9.54404 20.7163C9.84674 20.7495 11.1552 20.7495 11.4579 20.7163C12.7996 20.5679 13.9362 20.2359 15.0572 19.6637C15.229 19.5758 15.2622 19.5524 15.2388 19.5328C15.2232 19.5211 14.4908 18.5388 13.612 17.3514L12.0145 15.1934L10.0127 12.2308C8.91129 10.6021 8.00513 9.27017 7.99732 9.27017C7.9895 9.26822 7.98169 10.5845 7.97779 12.1918C7.97193 15.0059 7.96997 15.1192 7.93482 15.1856C7.88405 15.2813 7.84499 15.3204 7.76296 15.3633C7.70047 15.3946 7.64579 15.4004 7.35089 15.4004H7.01304L6.9232 15.3438C6.86461 15.3067 6.82165 15.2579 6.79236 15.2012L6.75134 15.1134L6.75525 11.1977L6.76111 7.28013L6.82165 7.20397C6.8529 7.16295 6.9193 7.11023 6.96617 7.08484C7.04624 7.04578 7.07748 7.04187 7.41534 7.04187C7.81374 7.04187 7.88014 7.0575 7.98365 7.17077C8.01294 7.20201 9.09682 8.83467 10.3936 10.8013C11.6903 12.7679 13.4636 15.4532 14.3346 16.7714L15.9165 19.1676L15.9965 19.1149C16.7055 18.654 17.4554 17.9978 18.0491 17.3143C19.3126 15.8633 20.127 14.0939 20.4004 12.2074C20.4805 11.6586 20.4902 11.4965 20.4902 10.7524C20.4902 10.0084 20.4805 9.84629 20.4004 9.29751C19.8575 5.54593 17.1878 2.39389 13.5671 1.22604C12.9285 1.01902 12.2489 0.87646 11.4872 0.790531C11.2997 0.771002 10.0088 0.749519 9.84674 0.765143ZM13.9362 6.80752C14.0299 6.85439 14.1061 6.94423 14.1334 7.03797C14.1491 7.08874 14.153 8.17457 14.1491 10.6216L14.1432 14.133L13.5241 13.1839L12.9031 12.2347V9.68224C12.9031 8.03201 12.9109 7.10437 12.9226 7.05945C12.9539 6.95009 13.0222 6.86416 13.116 6.81338C13.196 6.77237 13.2253 6.76846 13.5319 6.76846C13.821 6.76846 13.8717 6.77237 13.9362 6.80752Z"
          fill="currentColor"
        />
        <path
          d="M15.8174 19.2109C15.749 19.2539 15.7275 19.2832 15.7881 19.25C15.831 19.2246 15.9013 19.1719 15.8896 19.1699C15.8838 19.1699 15.8506 19.1895 15.8174 19.2109ZM15.6826 19.2988C15.6475 19.3262 15.6475 19.3281 15.6904 19.3066C15.7139 19.2949 15.7334 19.2812 15.7334 19.2773C15.7334 19.2617 15.7236 19.2656 15.6826 19.2988ZM15.585 19.3574C15.5498 19.3847 15.5498 19.3867 15.5928 19.3652C15.6162 19.3535 15.6357 19.3398 15.6357 19.3359C15.6357 19.3203 15.626 19.3242 15.585 19.3574ZM15.4873 19.416C15.4522 19.4433 15.4522 19.4453 15.4951 19.4238C15.5186 19.4121 15.5381 19.3984 15.5381 19.3945C15.5381 19.3789 15.5283 19.3828 15.4873 19.416ZM15.3389 19.4941C15.2647 19.5332 15.2686 19.5488 15.3428 19.5117C15.376 19.4941 15.4014 19.4765 15.4014 19.4726C15.4014 19.459 15.3994 19.4609 15.3389 19.4941Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5018_174167">
          <rect width="20" height="20" fill="white" transform="translate(0.5 0.75)" />
        </clipPath>
      </defs>
    </Svg>
  )
}
