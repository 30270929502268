import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const BorderRadiusStyle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      stroke="none"
      className={twMerge('h-[19px] w-[18px]', className)}
      viewBox="0 0 18 19"
      {...props}
    >
      <path
        d="M2.31337 16.2501L2.31545 11.8125C2.31545 9.625 1.81412 2.75 8.37364 2.75C11.5265 2.75 12.8836 2.75 15.7503 2.75"
        stroke="#9CA3AF"
        stroke-width="1.25"
      />
    </Svg>
  )
}
