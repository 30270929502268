import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const StatusUp: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 30 30"
      stroke="currentColor"
      strokeWidth={1}
      className={twMerge('h-5 w-5', className)}
      {...props}
    >
      <path
        d="M8.6001 23.6251C8.0876 23.6251 7.6626 23.2001 7.6626 22.6876V20.1001C7.6626 19.5876 8.0876 19.1626 8.6001 19.1626C9.1126 19.1626 9.5376 19.5876 9.5376 20.1001V22.6876C9.5376 23.2126 9.1126 23.6251 8.6001 23.6251Z"
        fill="#5A36FF"
      />
      <path
        d="M15 23.625C14.4875 23.625 14.0625 23.2 14.0625 22.6875V17.5C14.0625 16.9875 14.4875 16.5625 15 16.5625C15.5125 16.5625 15.9375 16.9875 15.9375 17.5V22.6875C15.9375 23.2125 15.5125 23.625 15 23.625Z"
        fill="#5A36FF"
      />
      <path
        d="M21.3999 23.6251C20.8874 23.6251 20.4624 23.2001 20.4624 22.6876V14.9126C20.4624 14.4001 20.8874 13.9751 21.3999 13.9751C21.9124 13.9751 22.3374 14.4001 22.3374 14.9126V22.6876C22.3374 23.2126 21.9249 23.6251 21.3999 23.6251Z"
        fill="#5A36FF"
      />
      <path
        d="M8.59997 16.4748C8.17497 16.4748 7.79997 16.1873 7.68747 15.7623C7.56247 15.2623 7.86247 14.7498 8.37497 14.6248C12.975 13.4748 17.025 10.9623 20.1125 7.37478L20.6875 6.69978C21.025 6.31228 21.6125 6.26228 22.0125 6.59978C22.4 6.93728 22.45 7.52478 22.1125 7.92478L21.5375 8.59978C18.2 12.4998 13.8 15.2123 8.82497 16.4498C8.74997 16.4748 8.67497 16.4748 8.59997 16.4748Z"
        fill="#5A36FF"
      />
      <path
        d="M21.3998 11.9C20.8873 11.9 20.4623 11.475 20.4623 10.9625V8.25H17.7373C17.2248 8.25 16.7998 7.825 16.7998 7.3125C16.7998 6.8 17.2248 6.375 17.7373 6.375H21.3998C21.9123 6.375 22.3373 6.8 22.3373 7.3125V10.975C22.3373 11.4875 21.9248 11.9 21.3998 11.9Z"
        fill="#5A36FF"
      />
      <path
        d="M18.75 28.4375H11.25C4.4625 28.4375 1.5625 25.5375 1.5625 18.75V11.25C1.5625 4.4625 4.4625 1.5625 11.25 1.5625H18.75C25.5375 1.5625 28.4375 4.4625 28.4375 11.25V18.75C28.4375 25.5375 25.5375 28.4375 18.75 28.4375ZM11.25 3.4375C5.4875 3.4375 3.4375 5.4875 3.4375 11.25V18.75C3.4375 24.5125 5.4875 26.5625 11.25 26.5625H18.75C24.5125 26.5625 26.5625 24.5125 26.5625 18.75V11.25C26.5625 5.4875 24.5125 3.4375 18.75 3.4375H11.25Z"
        fill="#5A36FF"
      />
    </Svg>
  )
}
