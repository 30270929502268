import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const LockClosed: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 10 13" className={twMerge('h-[13px] w-[10px]', className)} {...props}>
      <path
        d="M1.18714 5.96105C1.18714 5.89084 1.18714 5.83839 1.18714 5.7862C1.18929 5.21697 1.17154 4.64667 1.19736 4.07853C1.2864 2.11826 2.98197 0.529486 4.94681 0.500433C7.0276 0.469497 8.4859 2.10211 8.73796 3.53002C8.7845 3.79338 8.8071 4.06346 8.81355 4.33113C8.82539 4.82852 8.81974 5.32646 8.81463 5.82386C8.81355 5.92931 8.84234 5.96778 8.95048 5.95998C9.21895 5.94088 9.46752 5.9893 9.66739 6.19213C9.82315 6.35031 9.90842 6.53727 9.90869 6.75813C9.91004 8.40796 9.91192 10.0578 9.90762 11.7076C9.90654 12.1502 9.54795 12.4985 9.10462 12.4988C6.36987 12.5004 3.63512 12.5004 0.900375 12.4988C0.454895 12.4985 0.0987267 12.1539 0.0973816 11.7087C0.0925395 10.0546 0.0925395 8.40016 0.0973816 6.74575C0.0987267 6.31615 0.435795 5.98257 0.863789 5.96159C0.965475 5.95648 1.06743 5.96105 1.18714 5.96105ZM7.18094 5.95863V5.79723C7.18094 5.3044 7.17959 4.81131 7.18121 4.31848C7.18336 3.73393 6.98402 3.22846 6.57513 2.81338C5.92655 2.15511 5.13889 1.96465 4.28021 2.26648C3.42799 2.56589 2.91418 3.1994 2.83751 4.11431C2.7907 4.67519 2.82568 5.2428 2.81465 5.80691C2.81222 5.9355 2.85473 5.96132 2.9739 5.96105C4.32271 5.95729 5.67152 5.95863 7.02034 5.95863C7.06822 5.95863 7.11691 5.95863 7.18094 5.95863Z"
        fill="currentColor"
      />
    </Svg>
  )
}
