import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Handle: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="20 41 7 22" className={twMerge('h-5 w-5', className)} {...props}>
      <circle cx="21" cy="42" r="1" fill="#9CA3AF" />
      <circle cx="21" cy="47" r="1" fill="#9CA3AF" />
      <circle cx="21" cy="52" r="1" fill="#9CA3AF" />
      <circle cx="21" cy="57" r="1" fill="#9CA3AF" />
      <circle cx="21" cy="62" r="1" fill="#9CA3AF" />
      <circle cx="26" cy="42" r="1" fill="#9CA3AF" />
      <circle cx="26" cy="47" r="1" fill="#9CA3AF" />
      <circle cx="26" cy="52" r="1" fill="#9CA3AF" />
      <circle cx="26" cy="57" r="1" fill="#9CA3AF" />
      <circle cx="26" cy="62" r="1" fill="#9CA3AF" />
    </Svg>
  )
}
