import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Check: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg stroke="currentColor" className={twMerge('h-4 w-4 text-white', className)} {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
    </Svg>
  )
}
