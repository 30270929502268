import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const EyeCampaign: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 31 30"
      fill="currentColor"
      stroke="none"
      className={twMerge('h-5 w-5', className)}
      {...props}
    >
      <path d="M15.2499 20.4124C12.2624 20.4124 9.8374 17.9874 9.8374 14.9999C9.8374 12.0124 12.2624 9.5874 15.2499 9.5874C18.2374 9.5874 20.6624 12.0124 20.6624 14.9999C20.6624 17.9874 18.2374 20.4124 15.2499 20.4124ZM15.2499 11.4624C13.2999 11.4624 11.7124 13.0499 11.7124 14.9999C11.7124 16.9499 13.2999 18.5374 15.2499 18.5374C17.1999 18.5374 18.7874 16.9499 18.7874 14.9999C18.7874 13.0499 17.1999 11.4624 15.2499 11.4624Z" />
      <path d="M15.2501 26.2751C10.5501 26.2751 6.1126 23.5251 3.0626 18.7501C1.7376 16.6876 1.7376 13.3251 3.0626 11.2501C6.1251 6.4751 10.5626 3.7251 15.2501 3.7251C19.9376 3.7251 24.3751 6.4751 27.4251 11.2501C28.7501 13.3126 28.7501 16.6751 27.4251 18.7501C24.3751 23.5251 19.9376 26.2751 15.2501 26.2751ZM15.2501 5.6001C11.2126 5.6001 7.3501 8.0251 4.6501 12.2626C3.7126 13.7251 3.7126 16.2751 4.6501 17.7376C7.3501 21.9751 11.2126 24.4001 15.2501 24.4001C19.2876 24.4001 23.1501 21.9751 25.8501 17.7376C26.7876 16.2751 26.7876 13.7251 25.8501 12.2626C23.1501 8.0251 19.2876 5.6001 15.2501 5.6001Z" />
    </Svg>
  )
}
