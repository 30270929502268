import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const MedalStar: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 79 79" className={twMerge('h-32 w-32', className)} {...props}>
      <path
        d="M60.5 83.75C58.5 83.75 56.5 83.6 54.6 83.25C44 81.7 34.35 75.6 28.25 66.55C24 60.15 21.75 52.7 21.75 45C21.75 23.65 39.15 6.25 60.5 6.25C81.85 6.25 99.25 23.65 99.25 45C99.25 52.7 97 60.15 92.75 66.55C86.6 75.65 76.95 81.7 66.25 83.3C64.5 83.6 62.5 83.75 60.5 83.75ZM60.5 13.75C43.25 13.75 29.25 27.75 29.25 45C29.25 51.25 31.05 57.25 34.45 62.35C39.4 69.65 47.15 74.55 55.75 75.8C58.95 76.35 62.1 76.35 65.05 75.8C73.8 74.55 81.55 69.6 86.5 62.3C89.9 57.2 91.7 51.2 91.7 44.95C91.75 27.75 77.75 13.75 60.5 13.75Z"
        fill="#5A36FF"
      />
      <path
        d="M32.8564 112.95C32.1564 112.95 31.5064 112.85 30.8064 112.7C27.5564 111.95 25.0564 109.45 24.3064 106.2L22.5564 98.8498C22.4564 98.3998 22.1064 98.0498 21.6064 97.8998L13.3564 95.9499C10.2564 95.1999 7.80641 92.8998 6.95641 89.8498C6.10641 86.7998 6.95641 83.4998 9.20641 81.2498L28.7064 61.7499C29.5064 60.9499 30.6064 60.5499 31.7064 60.6499C32.8064 60.7499 33.8064 61.3498 34.4564 62.2998C39.4064 69.5998 47.1564 74.5499 55.8064 75.7999C59.0064 76.3499 62.1564 76.3499 65.1064 75.7999C73.8564 74.5499 81.6064 69.5998 86.5564 62.2998C87.1564 61.3498 88.2064 60.7499 89.3064 60.6499C90.4064 60.5499 91.5064 60.9499 92.3064 61.7499L111.806 81.2498C114.056 83.4998 114.906 86.7998 114.056 89.8498C113.206 92.8998 110.706 95.2499 107.656 95.9499L99.4064 97.8998C98.9564 97.9998 98.6064 98.3498 98.4564 98.8498L96.7064 106.2C95.9564 109.45 93.4564 111.95 90.2064 112.7C86.9564 113.5 83.6064 112.35 81.5064 109.8L60.5064 85.6499L39.5064 109.85C37.8064 111.85 35.4064 112.95 32.8564 112.95ZM30.9564 70.1498L14.5064 86.5999C14.0564 87.0499 14.1064 87.5499 14.2064 87.8499C14.2564 88.0999 14.5064 88.5998 15.1064 88.6998L23.3564 90.6499C26.6064 91.3999 29.1064 93.8998 29.8564 97.1498L31.6064 104.5C31.7564 105.15 32.2564 105.35 32.5564 105.45C32.8564 105.5 33.3564 105.55 33.8064 105.05L52.9564 82.9999C44.4564 81.3499 36.6564 76.7998 30.9564 70.1498ZM68.0564 82.9498L87.2064 104.95C87.6564 105.5 88.2064 105.5 88.5064 105.4C88.8064 105.35 89.2564 105.1 89.4564 104.45L91.2064 97.0999C91.9564 93.8499 94.4564 91.3498 97.7064 90.5998L105.956 88.6499C106.556 88.4999 106.806 88.0498 106.856 87.7998C106.956 87.5498 107.006 86.9998 106.556 86.5498L90.1064 70.0998C84.3564 76.7498 76.6064 81.2998 68.0564 82.9498Z"
        fill="#5A36FF"
      />
      <path
        d="M69.9556 64.4513C68.6556 64.4513 67.1056 64.1012 65.2556 63.0013L60.5056 60.1512L55.7556 62.9512C51.4056 65.5512 48.5556 64.0512 47.5056 63.3012C46.4556 62.5512 44.2056 60.3013 45.3556 55.3513L46.5556 50.2013L42.5556 46.5012C40.3556 44.3012 39.5556 41.6513 40.3056 39.2513C41.0556 36.8513 43.2556 35.1512 46.3556 34.6512L51.7056 33.7512L54.2556 28.1512C55.7056 25.3012 57.9556 23.7012 60.5056 23.7012C63.0556 23.7012 65.3556 25.3513 66.7556 28.2013L69.7056 34.1013L74.6556 34.7012C77.7056 35.2012 79.9056 36.9011 80.7056 39.3011C81.4556 41.7011 80.6556 44.3512 78.4556 46.5512L74.3056 50.7013L75.6056 55.3513C76.7556 60.3013 74.5056 62.5512 73.4556 63.3012C72.9056 63.7512 71.7056 64.4513 69.9556 64.4513ZM48.5556 41.9513L52.0056 45.4012C53.6056 47.0012 54.4056 49.7012 53.9056 51.9012L52.9556 55.9012L56.9556 53.5512C59.1056 52.3012 62.0056 52.3012 64.1056 53.5512L68.1056 55.9012L67.2056 51.9012C66.7056 49.6512 67.4556 47.0012 69.0556 45.4012L72.5056 41.9513L68.1556 41.2012C66.0556 40.8512 63.9556 39.3013 63.0056 37.4013L60.5056 32.5012L58.0056 37.5012C57.1056 39.3512 55.0056 40.9513 52.9056 41.3013L48.5556 41.9513Z"
        fill="#5A36FF"
      />
    </Svg>
  )
}
