import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const SearchNew: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 40 40"
      fill="currentColor"
      className={twMerge('h-10 w-10 text-gray-300', className)}
      {...props}
    >
      <path d="M19.1693 36.2497C9.7526 36.2497 2.08594 28.583 2.08594 19.1663C2.08594 9.74967 9.7526 2.08301 19.1693 2.08301C28.5859 2.08301 36.2526 9.74967 36.2526 19.1663C36.2526 28.583 28.5859 36.2497 19.1693 36.2497ZM19.1693 4.58301C11.1193 4.58301 4.58594 11.133 4.58594 19.1663C4.58594 27.1997 11.1193 33.7497 19.1693 33.7497C27.2193 33.7497 33.7526 27.1997 33.7526 19.1663C33.7526 11.133 27.2193 4.58301 19.1693 4.58301Z" />
      <path d="M36.6651 37.9161C36.3484 37.9161 36.0318 37.7994 35.7818 37.5494L32.4484 34.2161C31.9651 33.7327 31.9651 32.9327 32.4484 32.4494C32.9318 31.9661 33.7318 31.9661 34.2151 32.4494L37.5484 35.7827C38.0318 36.2661 38.0318 37.0661 37.5484 37.5494C37.2984 37.7994 36.9818 37.9161 36.6651 37.9161Z" />
    </Svg>
  )
}
