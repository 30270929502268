import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const CampaignIntentRadio: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 129 88" className={twMerge('h-16 w-20', className)} {...props}>
      <rect x="10.5" y="0.5" width="110" height="79" rx="0.5" fill="white" stroke="#D5D5D5" />
      <rect x="16.5" y="6.5" width="98" height="24" rx="0.5" fill="#ECECEC" stroke="#ECECEC" />
      <rect y="73" width="129" height="15" fill="white" />
      <rect x="86" y="27" width="42.9565" height="13" rx="1.85714" fill="#5A6ACF" />
      <path
        d="M101.501 34.5112C101.829 34.8388 102.307 35.0624 102.973 35.0624C103.909 35.0624 104.361 34.584 104.361 33.9496C104.361 33.18 103.633 33.0084 103.061 32.8784C102.661 32.7848 102.37 32.712 102.37 32.478C102.37 32.27 102.546 32.1244 102.879 32.1244C103.217 32.1244 103.592 32.244 103.872 32.504L104.283 31.9632C103.94 31.646 103.482 31.4796 102.931 31.4796C102.115 31.4796 101.616 31.9476 101.616 32.5248C101.616 33.2996 102.344 33.4556 102.91 33.5856C103.311 33.6792 103.612 33.7676 103.612 34.0276C103.612 34.2252 103.415 34.4176 103.009 34.4176C102.531 34.4176 102.151 34.2044 101.907 33.9496L101.501 34.5112ZM106.543 35H107.204V33.2164C107.204 32.7276 106.938 32.426 106.387 32.426C105.976 32.426 105.664 32.6236 105.503 32.816V31.5316H104.843V35H105.503V33.31C105.618 33.1592 105.82 33.0136 106.075 33.0136C106.361 33.0136 106.543 33.1228 106.543 33.4712V35ZM109.024 35.0624C109.856 35.0624 110.355 34.4592 110.355 33.7416C110.355 33.0292 109.856 32.426 109.024 32.426C108.197 32.426 107.698 33.0292 107.698 33.7416C107.698 34.4592 108.197 35.0624 109.024 35.0624ZM109.024 34.4748C108.613 34.4748 108.384 34.1368 108.384 33.7416C108.384 33.3516 108.613 33.0136 109.024 33.0136C109.435 33.0136 109.669 33.3516 109.669 33.7416C109.669 34.1368 109.435 34.4748 109.024 34.4748ZM111.506 35.9568V34.6776C111.708 34.9376 111.984 35.0624 112.28 35.0624C112.925 35.0624 113.393 34.5788 113.393 33.7416C113.393 32.9044 112.925 32.426 112.28 32.426C111.974 32.426 111.698 32.5612 111.506 32.8056V32.4884H110.845V35.9568H111.506ZM112.707 33.7416C112.707 34.1784 112.452 34.4748 112.072 34.4748C111.859 34.4748 111.615 34.3448 111.506 34.1888V33.2996C111.615 33.1384 111.859 33.0136 112.072 33.0136C112.452 33.0136 112.707 33.31 112.707 33.7416Z"
        fill="white"
      />
    </Svg>
  )
}
