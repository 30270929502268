import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Desktop: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M14.8065 14.9734H5.53984C2.22318 14.9734 1.21484 13.965 1.21484 10.6484V5.36504C1.21484 2.04837 2.22318 1.04004 5.53984 1.04004H14.7982C18.1148 1.04004 19.1232 2.04837 19.1232 5.36504V10.64C19.1315 13.965 18.1232 14.9734 14.8065 14.9734ZM5.53984 2.29004C2.92318 2.29004 2.46484 2.74837 2.46484 5.36504V10.64C2.46484 13.2567 2.92318 13.715 5.53984 13.715H14.7982C17.4148 13.715 17.8732 13.2567 17.8732 10.64V5.36504C17.8732 2.74837 17.4148 2.29004 14.7982 2.29004H5.53984Z"
        fill="currentColor"
      />
      <path
        d="M10.1719 18.9589C9.83021 18.9589 9.54688 18.6756 9.54688 18.3339V14.3506C9.54688 14.0089 9.83021 13.7256 10.1719 13.7256C10.5135 13.7256 10.7969 14.0089 10.7969 14.3506V18.3339C10.7969 18.6756 10.5135 18.9589 10.1719 18.9589Z"
        fill="currentColor"
      />
      <path
        d="M18.5065 11.46H1.83984C1.49818 11.46 1.21484 11.1766 1.21484 10.835C1.21484 10.4933 1.49818 10.21 1.83984 10.21H18.5065C18.8482 10.21 19.1315 10.4933 19.1315 10.835C19.1315 11.1766 18.8482 11.46 18.5065 11.46Z"
        fill="currentColor"
      />
      <path
        d="M13.9219 18.96H6.42188C6.08021 18.96 5.79688 18.6766 5.79688 18.335C5.79688 17.9933 6.08021 17.71 6.42188 17.71H13.9219C14.2635 17.71 14.5469 17.9933 14.5469 18.335C14.5469 18.6766 14.2635 18.96 13.9219 18.96Z"
        fill="currentColor"
      />
    </Svg>
  )
}
