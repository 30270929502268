import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

const Loading: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg className={twMerge('h-5 w-5 animate-spin', className)} {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
      />
    </Svg>
  )
}

const Enabled: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg title="Enabled" className={twMerge('h-5 w-5', className)} fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

const Disabled: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg title="Disabled" className={twMerge('h-5 w-5', className)} fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export const ItemStatus = { Loading, Enabled, Disabled }
