import { Level } from 'iconsax-react'

import { A } from '@/components/shared/common/icon/A'
import { AcademicCap } from '@/components/shared/common/icon/AcademicCap'
import { ArrowLeft } from '@/components/shared/common/icon/ArrowLeft'
import { ArrowRight } from '@/components/shared/common/icon/ArrowRight'
import { Bell } from '@/components/shared/common/icon/Bell'
import { BorderRadiusStyle } from '@/components/shared/common/icon/BorderRadiusStyle'
import { BorderWidthStyle } from '@/components/shared/common/icon/BorderWidthStyle'
import { Box } from '@/components/shared/common/icon/Box'
import { BoxDotted } from '@/components/shared/common/icon/BoxDotted'
import { BoxDottedBlocked } from '@/components/shared/common/icon/BoxDottedBlocked'
import { CampaignIntentRadio } from '@/components/shared/common/icon/CampaignIntentRadio'
import { CampaignIntentRadioComingSoon } from '@/components/shared/common/icon/CampaignIntentRadioComingSoon'
import { CampaignTimer } from '@/components/shared/common/icon/CampaignTimer'
import { Check } from '@/components/shared/common/icon/Check'
import { CheckCircle } from '@/components/shared/common/icon/CheckCircle'
import { ChevronDown } from '@/components/shared/common/icon/ChevronDown'
import { ChevronLeft } from '@/components/shared/common/icon/ChevronLeft'
import { ChevronRight } from '@/components/shared/common/icon/ChevronRight'
import { ChevronSmallDown } from '@/components/shared/common/icon/ChevronSmallDown'
import { ChevronSmallLeft } from '@/components/shared/common/icon/ChevronSmallLeft'
import { ChevronSmallRight } from '@/components/shared/common/icon/ChevronSmallRight'
import { ChevronThinLeft } from '@/components/shared/common/icon/ChevronThinLeft'
import { ChevronThinRight } from '@/components/shared/common/icon/ChevronThinRight'
import { ChevronUp } from '@/components/shared/common/icon/ChevronUp'
import { Click } from '@/components/shared/common/icon/Click'
import { Close } from '@/components/shared/common/icon/Close'
import { CloseError } from '@/components/shared/common/icon/CloseError'
import { Cog } from '@/components/shared/common/icon/Cog'
import { Color } from '@/components/shared/common/icon/Color'
import { Cross } from '@/components/shared/common/icon/Cross'
import { Desktop } from '@/components/shared/common/icon/Desktop'
import { Document } from '@/components/shared/common/icon/Document'
import { DotsHorizontal } from '@/components/shared/common/icon/DotsHorizontal'
import { DotsVertical } from '@/components/shared/common/icon/DotsVertical'
import { EmbeddedScript } from '@/components/shared/common/icon/EmbeddedScript'
import { ExclamationCircle } from '@/components/shared/common/icon/ExclamationCircle'
import { Eye } from '@/components/shared/common/icon/Eye'
import { Eye2Campaign } from '@/components/shared/common/icon/Eye2Campaign'
import { EyeCampaign } from '@/components/shared/common/icon/EyeCampaign'
import { FontSize } from '@/components/shared/common/icon/FontSize'
import { Funnel } from '@/components/shared/common/icon/Funnel'
import { GoalFlowArrow } from '@/components/shared/common/icon/GoalFlowArrow'
import { Handle } from '@/components/shared/common/icon/Handle'
import { Help } from '@/components/shared/common/icon/Help'
import { HideText } from '@/components/shared/common/icon/HideText'
import { HTMLLogo } from '@/components/shared/common/icon/HTMLLogo'
import { InformationCircle } from '@/components/shared/common/icon/InformationCircle'
import { Interacting } from '@/components/shared/common/icon/Interacting'
import { ItemStatus } from '@/components/shared/common/icon/ItemStatus'
import { Loader } from '@/components/shared/common/icon/Loader'
import { LockClosed } from '@/components/shared/common/icon/LockClosed'
import { Logo } from '@/components/shared/common/icon/Logo'
import { Logout } from '@/components/shared/common/icon/Logout'
import { MedalStar } from '@/components/shared/common/icon/MedalStar'
import { Minus } from '@/components/shared/common/icon/Minus'
import { Mobile } from '@/components/shared/common/icon/Mobile'
import { MouseCircle } from '@/components/shared/common/icon/MouseCircle'
import { NextjsLogo } from '@/components/shared/common/icon/NextjsLogo'
import { PaddingStyle } from '@/components/shared/common/icon/PaddingStyle'
import { Paragraph } from '@/components/shared/common/icon/Paragraph'
import { People } from '@/components/shared/common/icon/People'
import { Plus } from '@/components/shared/common/icon/Plus'
import { Preview } from '@/components/shared/common/icon/Preview'
import { ReactLogo } from '@/components/shared/common/icon/ReactLogo'
import { RecoveryConvert } from '@/components/shared/common/icon/RecoveryConvert'
import { RevealText } from '@/components/shared/common/icon/RevealText'
import { Search } from '@/components/shared/common/icon/Search'
import { SearchNew } from '@/components/shared/common/icon/SearchNew'
import { Settings } from '@/components/shared/common/icon/Settings'
import { ShareUp } from '@/components/shared/common/icon/ShareUp'
import { ShopifyLogo } from '@/components/shared/common/icon/ShopifyLogo'
import { Slash } from '@/components/shared/common/icon/Slash'
import { SmsNotification } from '@/components/shared/common/icon/SmsNotification'
import { Spinner } from '@/components/shared/common/icon/Spinner'
import { Star } from '@/components/shared/common/icon/Star'
import { StatusUp } from '@/components/shared/common/icon/StatusUp'
import { Table } from '@/components/shared/common/icon/Table'
import { Tablet } from '@/components/shared/common/icon/Tablet'
import { TeamSwitcher } from '@/components/shared/common/icon/TeamSwitcher'
import { Tone } from '@/components/shared/common/icon/Tone'
import { TriangleDown } from '@/components/shared/common/icon/TriangleDown'
import { Upload } from '@/components/shared/common/icon/Upload'
import { VariantErrorRibbon } from '@/components/shared/common/icon/VariantErrorRibbon'
import { Video } from '@/components/shared/common/icon/Video'
import { WordPressLogo } from '@/components/shared/common/icon/WordPressLogo'

export type { Props as IconProps } from './Svg'

export const Icon = {
  A,
  AcademicCap,
  ArrowLeft,
  ArrowRight,
  Bell,
  Box,
  BoxDotted,
  BoxDottedBlocked,
  CampaignIntentRadio,
  CampaignIntentRadioComingSoon,
  CampaignTimer,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronSmallDown,
  ChevronSmallLeft,
  ChevronSmallRight,
  ChevronThinLeft,
  ChevronThinRight,
  ChevronUp,
  Click,
  Close,
  CloseError,
  Cog,
  Color,
  Cross,
  Desktop,
  Document,
  DotsHorizontal,
  DotsVertical,
  EmbeddedScript,
  ExclamationCircle,
  Eye,
  Eye2Campaign,
  EyeCampaign,
  FontSize,
  GoalFlowArrow,
  Handle,
  Help,
  HideText,
  HTMLLogo,
  InformationCircle,
  Interacting,
  ItemStatus,
  Level,
  Loader,
  LockClosed,
  Logo,
  Logout,
  MedalStar,
  Minus,
  Mobile,
  MouseCircle,
  NextjsLogo,
  Paragraph,
  Plus,
  Preview,
  ReactLogo,
  RecoveryConvert,
  RevealText,
  Search,
  SearchNew,
  Settings,
  ShareUp,
  ShopifyLogo,
  Slash,
  SmsNotification,
  Spinner,
  Star,
  StatusUp,
  Table,
  Tablet,
  TeamSwitcher,
  Tone,
  TriangleDown,
  Upload,
  VariantErrorRibbon,
  Video,
  WordPressLogo,
  People,
  Funnel,
  BorderRadiusStyle,
  PaddingStyle,
  BorderWidthStyle
}
