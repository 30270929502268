import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const DotsHorizontal: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      viewBox="0 0 22 4"
      stroke="currentColor"
      strokeWidth={2}
      className={twMerge('h-5 w-5', className)}
      {...props}
    >
      <circle cx="2" cy="2" r="1.1" fill="#4B5563" />
      <circle cx="10" cy="2" r="1.1" fill="#4B5563" />
      <circle cx="18" cy="2" r="1.1" fill="#4B5563" />
    </Svg>
  )
}
