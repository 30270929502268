import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Mobile: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        d="M11.8862 18.5417H8.45759C7.27648 18.5417 6.71802 18.2809 6.38519 17.7955C5.99878 17.232 5.79688 16.2153 5.79688 14.375V6.04167C5.79688 4.20137 5.99878 3.18467 6.38519 2.62116C6.71802 2.13578 7.27648 1.875 8.45759 1.875H11.8862C13.0673 1.875 13.6257 2.13578 13.9586 2.62116C14.345 3.18467 14.5469 4.20137 14.5469 6.04167V14.375C14.5469 16.2153 14.345 17.232 13.9586 17.7955C13.6257 18.2809 13.0673 18.5417 11.8862 18.5417Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <rect x="8.67188" y="2" width="3" height="1" rx="0.5" fill="currentColor" />
    </Svg>
  )
}
