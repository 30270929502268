import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Slash: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg fill="currentColor" className={twMerge('h-[24px] w-[24px]', className)} {...props}>
      <path strokeWidth={props.strokeWidth} d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </Svg>
  )
}
