import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const CampaignTimer: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 52 52" className={twMerge('h-12 w-12', className)} {...props}>
      <path
        d="M33.0204 49.2913H18.9804C14.8854 49.2913 11.8954 47.5797 10.7471 44.633C9.55543 41.5563 10.6604 37.743 13.5204 35.1647L23.5738 25.9997L13.5204 16.8347C10.6604 14.2563 9.55543 10.443 10.7471 7.36634C11.8954 4.39801 14.8854 2.70801 18.9804 2.70801H33.0204C37.1154 2.70801 40.1054 4.41967 41.2538 7.36634C42.4454 10.443 41.3404 14.2563 38.4804 16.8347L28.4271 25.9997L38.5021 35.1647C41.3404 37.743 42.4671 41.5563 41.2754 44.633C40.1054 47.5797 37.1154 49.2913 33.0204 49.2913ZM26.0004 28.188L15.7088 37.548C13.8888 39.2163 13.0871 41.643 13.7804 43.4413C14.4304 45.1097 16.2721 46.0413 18.9804 46.0413H33.0204C35.7288 46.0413 37.5704 45.1313 38.2204 43.4413C38.9138 41.643 38.1338 39.2163 36.2921 37.548L26.0004 28.188ZM18.9804 5.95801C16.2721 5.95801 14.4304 6.86801 13.7804 8.55801C13.0871 10.3563 13.8671 12.783 15.7088 14.4513L26.0004 23.8113L36.2921 14.4513C38.1121 12.783 38.9138 10.3563 38.2204 8.55801C37.5704 6.88967 35.7288 5.95801 33.0204 5.95801H18.9804Z"
        fill="#0396F0"
      />
      <path
        d="M13 41C13 38.2386 15.2386 36 18 36H34C37.3137 36 40 38.6863 40 42V44C40 45.6569 38.6569 47 37 47H16C14.3431 47 13 45.6569 13 44V41Z"
        fill="#0396F0"
      />
    </Svg>
  )
}
