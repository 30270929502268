import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Close: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg stroke="currentColor" className={twMerge('h-7 w-7', className)} {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth}
        d="M6 18L18 6M6 6l12 12"
      />
    </Svg>
  )
}
