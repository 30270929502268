import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const Logo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      className={twMerge('h-[40px] w-[87px] text-[#5A36FF]', className)}
      viewBox="0 0 956.12 473.29"
      {...props}
    >
      <g id="Layer_1">
        <path
          className="cls-1"
          fill="currentColor"
          d="M929.91,270.41c-17.48-21.61-39.78-34.88-66.91-39.84,24.83-7.65,44.72-20.7,59.66-39.17,14.94-18.46,22.42-41.42,22.42-68.88,0-23.4-6.1-44.34-18.28-62.8-12.19-18.46-30.12-32.86-53.8-43.22-23.69-10.35-51.85-15.53-84.5-15.53h-16.44c4.77,1.63,9.38,3.45,13.83,5.45,23.68,10.68,41.61,25.52,53.8,44.54,12.18,19.03,18.28,40.6,18.28,64.72,0,28.3-7.48,51.96-22.42,70.98-14.95,19.03-34.83,32.48-59.66,40.36,27.13,5.11,49.43,18.79,66.91,41.06,17.47,22.27,26.21,47.79,26.21,76.55,0,25.99-6.33,48.83-18.97,68.54-12.65,19.73-30.93,35.14-54.83,46.28-12.54,5.84-26.28,10.13-41.21,12.9h43.47c32.64,0,60.92-5.4,84.84-16.21,23.9-10.8,42.18-25.77,54.83-44.91,12.64-19.13,18.97-41.3,18.97-66.52,0-27.91-8.74-52.67-26.21-74.28Z"
        />
      </g>
      <g id="_Layer_">
        <path
          className="cls-1"
          fill="currentColor"
          d="M773.58,344.81c0-27.98-8.58-52.81-25.73-74.48-17.03-21.5-38.72-34.76-65.07-39.82,24.09-7.7,43.41-20.7,57.96-39.06,14.66-18.5,22.01-41.52,22.01-69.06,0-.06,0-.12,0-.17,0-.06,0-.11,0-.17,0-23.46-5.99-44.45-17.94-62.97-11.97-18.5-29.57-32.94-52.81-43.33C668.74,5.36,641.09.17,609.05.17h-195v472.61h94.79v.34h109.01c32.05,0,59.81-5.42,83.28-16.25,23.46-10.83,41.41-25.83,53.83-45.03,12.41-19.18,18.62-41.41,18.62-66.69,0-.05,0-.1,0-.15,0-.06,0-.12,0-.19ZM508.84,161.81v-84.79h86.67c5.64,0,10.96.32,15.95.97,14.98,1.95,27.04,6.82,36.18,14.6,12.19,10.39,18.28,25.05,18.28,44.01,0,14.22-3.43,25.96-10.28,35.23-2.29,3.09-4.95,5.9-8,8.44-1.29,1.08-2.67,2.07-4.08,3.03-.38.26-.76.53-1.15.79-11.76,7.6-27.38,11.42-46.9,11.42h-86.67v-33.7ZM508.84,295.38v-26.75h93.44c15.54,0,28.91,2.63,40.13,7.88h0c2.59,1.21,5.07,2.57,7.43,4.06.24.15.48.31.72.47.89.58,1.77,1.18,2.63,1.8.28.2.56.39.83.6,1.06.79,2.1,1.61,3.11,2.46,12.7,10.79,19.44,25.45,20.23,43.98.05,1.24.08,2.49.08,3.76,0,19.87-6.43,35.21-19.3,46.04-12.86,10.84-30.81,16.25-53.83,16.25h-95.47v-100.55Z"
        />
        <path
          className="cls-1"
          fill="currentColor"
          d="M153.7,0c-32.05,0-59.69,5.2-82.94,15.57-23.24,10.39-40.85,24.83-52.81,43.33C5.99,77.42,0,98.41,0,121.88c0,.06,0,.11,0,.17,0,.06,0,.12,0,.17,0,2.3.07,4.56.17,6.8h-.17v344.28h94.79v-204.82h160.91v204.14h93V0h-195ZM253.91,161.64v33.7H96.82v-59.25h0c.07-18.79,6.16-33.35,18.27-43.67,9.14-7.78,21.2-12.65,36.18-14.6,4.99-.65,10.31-.97,15.95-.97h86.67v84.79Z"
        />
      </g>
    </Svg>
  )
}
