import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ChevronLeft: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg viewBox="0 0 19 18" className={twMerge('h-[18px] w-[19px]', className)} {...props}>
      <g id="asicon/outline/arrow-right">
        <g id="vuesax/outline/arrow-right">
          <g id="arrow-right">
            <path
              id="Vector"
              d="M11.8183 15.5031C11.9608 15.5031 12.1033 15.4506 12.2158 15.3381C12.4333 15.1206 12.4333 14.7606 12.2158 14.5431L7.32578 9.65313C6.96578 9.29313 6.96578 8.70813 7.32578 8.34812L12.2158 3.45813C12.4333 3.24063 12.4333 2.88063 12.2158 2.66313C11.9983 2.44563 11.6383 2.44563 11.4208 2.66313L6.53078 7.55312C6.14828 7.93562 5.93078 8.45313 5.93078 9.00063C5.93078 9.54813 6.14078 10.0656 6.53078 10.4481L11.4208 15.3381C11.5333 15.4431 11.6758 15.5031 11.8183 15.5031Z"
            />
          </g>
        </g>
      </g>
    </Svg>
  )
}
