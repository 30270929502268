import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const ChevronSmallRight: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg fill="currentColor" className={twMerge('h-5 w-5', className)} {...props}>
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </Svg>
  )
}
