import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Props, Svg } from '@/components/shared/common/Svg'

export const A: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Svg
      fill="currentColor"
      viewBox="0 0 46 46"
      className={twMerge('h-5 w-5', className)}
      {...props}
    >
      <path
        d="M23.0177 43.5859C21.8102 43.5859 20.6219 43.1834 19.6827 42.3784L16.6543 39.7909C16.3477 39.5226 15.581 39.2543 15.1785 39.2543H11.8243C8.98768 39.2543 6.68768 36.9543 6.68768 34.1176V30.8401C6.68768 30.4376 6.41935 29.6709 6.17018 29.3834L3.56352 26.3168C1.99185 24.4576 1.99185 21.5443 3.56352 19.6659L6.17018 16.5993C6.41935 16.3118 6.68768 15.5451 6.68768 15.1426V11.8843C6.68768 9.04759 8.98768 6.74759 11.8243 6.74759H15.1402C15.5427 6.74759 16.2902 6.46009 16.616 6.19176L19.6444 3.60426C21.5227 2.01342 24.4552 2.01342 26.3335 3.60426L29.3618 6.19176C29.6685 6.46009 30.4544 6.72842 30.8569 6.72842H34.1152C36.9518 6.72842 39.2518 9.02842 39.2518 11.8651V15.1234C39.2518 15.5259 39.5393 16.2734 39.8077 16.5993L42.3952 19.6276C44.0052 21.5251 43.986 24.4576 42.3952 26.3168L39.8077 29.3451C39.5393 29.6709 39.271 30.4184 39.271 30.8209V34.0793C39.271 36.9159 36.971 39.2159 34.1343 39.2159H30.876C30.4735 39.2159 29.726 39.5034 29.381 39.7718L26.3527 42.3593C25.4135 43.1834 24.206 43.5859 23.0177 43.5859ZM11.8243 9.62259C10.5785 9.62259 9.56268 10.6384 9.56268 11.8843V15.1426C9.56268 16.2351 9.06435 17.6151 8.35518 18.4584L5.74852 21.5251C5.09685 22.3109 5.09685 23.6909 5.74852 24.4576L8.33602 27.5051C9.02602 28.2909 9.54352 29.7284 9.54352 30.8209V34.0984C9.54352 35.3443 10.5593 36.3601 11.8052 36.3601H15.1402C16.2135 36.3601 17.6319 36.8776 18.4752 37.5868L21.5227 40.1934C22.3085 40.8643 23.6694 40.8643 24.4744 40.1934L27.5027 37.6059C28.3652 36.8776 29.7644 36.3793 30.8377 36.3793H34.096C35.3419 36.3793 36.3577 35.3634 36.3577 34.1176V30.8593C36.3577 29.7859 36.8752 28.3868 37.5844 27.5243L40.191 24.4768C40.8618 23.6909 40.8618 22.3109 40.191 21.5251L37.6035 18.4968C36.8752 17.6343 36.3768 16.2351 36.3768 15.1618V11.8843C36.3768 10.6384 35.361 9.62259 34.1152 9.62259H30.8569C29.7644 9.62259 28.346 9.10509 27.5027 8.39593L24.4552 5.78926C23.6693 5.11842 22.3085 5.11842 21.5035 5.78926L18.4943 8.39593C17.6318 9.10509 16.2327 9.62259 15.1402 9.62259H11.8243Z"
        fill="#C600FF"
      />
      <path
        d="M28.3874 31.1264L23.0015 18.9939L17.6157 31.1264L14.9707 29.9572L21.679 14.873H24.324L31.0324 29.9572L28.3874 31.1264Z"
        fill="#C600FF"
      />
      <path
        d="M26.3548 26.9473H19.6465C18.8607 26.9473 18.209 26.2956 18.209 25.5098C18.209 24.7239 18.8607 24.0723 19.6465 24.0723H26.3548C27.1407 24.0723 27.7923 24.7239 27.7923 25.5098C27.7923 26.2956 27.1407 26.9473 26.3548 26.9473Z"
        fill="#C600FF"
      />
    </Svg>
  )
}
